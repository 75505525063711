import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../Footer";

import "./AutoresIdex.css";

// const verbetessURL = 'http://localhost:3004/api/umahistoriaAutores/'

const verbetessURL = "https://backautores.onrender.com/api/umahistoriaAutores/";

const AutoreIndex = (props, index) => {
  const createMarkup = (html) => {
    return { __html: html };
  };

  const { id } = useParams();
  const [verbete, setVerbete] = useState(null);

  const getVerbete = async (url) => {
    const res = await fetch(url);
    const data = await res.json();
    console.log(data);
    setVerbete(data);
  };

  useEffect(() => {
    const verbeteUrl = `${verbetessURL}${id}`;
    getVerbete(verbeteUrl);
  }, []);

  return (
    <div>
      <div class="TituloBancodeDados">
        <div class="row">
          <div class="col-md-12">
            <h1>Autores</h1>
          </div>
        </div>
      </div>

      <section class="about-2 section " id="about">
        <div class="container">
          <div class="row">
            <div class="col-md-12 ml-auto mr-auto text-center p-2"></div>

            {verbete && (
              <div class="col-12">
                <div class="text-center">
                  <img class="img-fluid-autores" src={verbete.imgfoto} />
                  <h1 className="h1Autor">{verbete.autor} </h1>
                  <p className="pAutores"> {verbete.subtitle}</p>
                </div>

                <div class="border"></div>
                <div class="text-center">
                  <p
                    className="autores"
                    dangerouslySetInnerHTML={createMarkup(verbete.likcv)}
                  />
                </div>

                <h3 class="text-center p-2 text-lg-center">
                  Alguns Trabalhos{" "}
                </h3>

                <div className="divAlgunsTrabalhos">
                  <div className="div-ptextoautor">
                    <ul class="checklist">
                      <p
                        className="ptextoautor"
                        dangerouslySetInnerHTML={createMarkup(verbete.texto)}
                      />
                      <p
                        className="linkdescription"
                        dangerouslySetInnerHTML={createMarkup(verbete.linkdesc)}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AutoreIndex;
