import React, { useEffect } from 'react';
import './ArtigoI.css';
import Imagem_artigoIII from '../../../assets/img/Imagem_artigoIII.jpg'
import capaDoLivro from '../../../assets/img/capaDoLivro_artigoIII.png'

import Footer from '../../../components/Footer';

const ArtigoIII = () => {

  useEffect(() => {
    // Envia o evento de visualização de página para o Google Analytics
    window.gtag('config', 'G-XPPGYZXKW6', {
      page_path: 'https://www.googletagmanager.com/gtag/js?id=G-XPPGYZXKW6'
    });
  }, []);
  return (
<div>
     <div class="TituloArtigoI">
        <div class="row">
         <div class="col-md-12">
            <h1>Divulgação Científica</h1> 
        </div>
     </div>  
    </div> 

    <div className="containerDivulgacao">
    
        <main className="main-contentDivulgacao">
        <h1 className="titleDivulgacao">
        Escritos catequéticos da Castela medieval ganham tradução inédita para o português
        </h1><br></br>
        <p className="subtitleDivulgacao">Livro, publicado pela Editora da Unicamp, traz a tradução de seis opúsculos</p>
        <div className="text-contentDivulgacao">
        <p className="highlight-letter">
       
        </p>
</div>
<div className="contentDivulgacao">
        <div className="book-image">
        <img src={capaDoLivro} alt="Capa do Livro" style={{ width: '400px', height: 'auto' }} />
        </div>
          <div className="text-contentDivulgacao">
          <p className="text-contentDivulgacao">
          <em>Guias dos costumes cristãos castelhanos: o Espelho da alma e outros opúsculos pastorais dos séculos XIII, XIV e XV</em>
            </p>     
            <p className="text-contentDivulgacao">
            Leandro Alves Teodoro – Tradução, introdução e notas. 
        
            <p className='text-contentDivulgacao'>Editora da Unicamp | 338 páginas | R$ 50,00.</p>   </p>
        <br></br>
 
       
          <p className="highlight-letter">
   O que homens e mulheres de fins da Idade Média no reino de Castela deveriam pôr em prática para merecerem a salvação? 
   Quais as lições cristãs deveriam saber ou aprender? Essas e outras perguntas embasam o livro <em>Guias dos costumes cristãos castelhanos: o Espelho da alma e outros opúsculos pastorais dos séculos XIII, XIV e XV, </em>
   uma antologia de pequenos tratados traduzidos pela primeira vez em língua portuguesa, pelo historiador Leandro Alves Teodoro, professor de História Medieval na Faculdade de Ciências e Letras da Universidade Estadual Paulista (UNESP), Câmpus de Assis. A obra é um dos trabalhos produzidos no âmbito da pesquisa “O ensino da fé cristã na península Ibérica (séculos XIV, XV e XVI)”, financiada pela Fundação de Apoio à Pesquisa do Estado de São Paulo (FAPESP), na modalidade auxílio Jovem Pesquisador. 
  </p>
          </div>
             
          </div>
         

          <p className="paragraph-content">
          Publicado pela Editora da Unicamp, o livro <em>Guias dos costumes cristãos castelhanos </em>dá continuidade às reflexões de pesquisa do historiador acerca do ensino cristão em terras ibéricas medievais, já evidenciadas em outras obras, entre as quais, <em>Guias dos costumes cristãos: os primeiros opúsculos pastorais em Língua Portuguesa</em> (Unifesp, 2019) e <em>Cativar as almas. Diretrizes para a instrução espiritual, séc. XII-XV </em>
          (Unisinos, 2022) – esta última organizada com os historiadores Jean-Claude Schmitt, professor emérito da Escola de Altos Estudos em Ciências Sociais <em> (École des Hautes Études en Sciences Sociales), </em>da França, e Pablo Martín Prieto, professor da Universidad Complutense de Madrid (Espanha).
</p>

<p className="paragraph-content">
“Foi um trabalho que começou por volta de 2019, mas que foi interrompido para a realização de outros trabalhos em cada ano.
O mais demorado foi, certamente, a revisão das traduções, já que são textos com estruturas difíceis” diz o historiador. Além da versão inédita em português dos opúsculos originalmente compostos em castelhanos, a obra contém uma introdução pormenorizada da época de produção
desses escritos em Castela, um breve estudo de cada tratado no início dos capítulos e notas explicativas. A antologia traz ainda uma apresentação do historiador David Nogales Rincón, professor de História Medieval da Faculdade de Filosofia e Letras da Universidad Autónoma de Madrid (Espanha).
    </p>

    
    <p className="paragraph-content">
    O volume é formado de seis tratados produzidos entre os fins do século XIII e fins do século XV na Coroa de Castela. “Trata-se de guias de comportamentos que buscavam estabelecer práticas alimentares, gestuais, palavras, em suma, o que eram considerados bons costumes, à comunidade de cristãos”, conta Teodoro, que se utilizou de versões manuscritas, incunábulos (nome dado aos primeiros livros impressos com tipos móveis) e edições críticas dos documentos no processo tradutório. Fazem parte da obra a
     <em> Glosa do Pai-Nosso (Glosa del Pater Noster), </em> de fins do século XIII, atribuída ao bispo de Jaén, D. Pedro; o <em>Contra os fados (Contra las hadas), </em> provavelmente, do século XIV, atribuído ao judeu convertido Alfonso de Valladolid (1270-1346); as constituições sinodais de 1303, do bispo de Leão D. Gonzalo Osorio (?-1327); o <em>Espelho da alma (Espejo del alma), </em> composto no século XV, por Fr. Lope Fernández de Minaya (ca.1375-ca.1438); a 
      <em> Breve doutrina e ensinança (Breve doctrina y enseñança) </em> e o <em>Proveitoso tratado de como devemos haver muito cuidado em expender mui bem o tempo (Provechoso tractado de cómo devemos aver mucho cuydado de espender muy bien el tiempo),</em> ambos impressos em 1496, escritos por Fr. Hernando de Talavera (1428-1507), confessor da rainha D. Isabel (1451-1504) e arcebispo de Granada.
 </p>




<br></br>
<div className="section-title">
        <h2>DO PAI-NOSSO À DEFESA DA FÉ CRISTÃ</h2>
      </div>
      <p className='paragraph-normal'> 
      A recuperação de territórios antes dominados pelos mouros (como os cristãos chamavam os muçulmanos), as ameaças de crenças consideradas  heréticas, supersticiosas ou professadas por outros grupos (judeus e muçulmanos), assim como a reorganização das dioceses ibéricas estão entre os fatores que impulsionaram as paróquias castelhanas na missão de instruir homens e mulheres na doutrina cristã. Em especial, no século XIV, com o Concílio de Valladolid, de 1322, e o incentivo dos reis da dinastia de Trastâmara (1369-1516), tais esforços tornaram-se 
      mais visíveis. Nesse cenário, a escassez de materiais destinados à aprendizagem elementar da fé católica levou à produção de obras que pudessem ajudar os clérigos e pregadores no empenho pastoral da população. Os opúsculos escolhidos para a antologia são resultantes desse contexto. “No mundo castelhano do final da Idade Média, há uma variedade grande de obras sobre os costumes. Não tive a pretensão de mapear o que pudesse ser a totalidade, mas apresentar uma amostra, ou seja, alguns textos representativos de cada gênero que ajudou a fixar costumes cristãos”, explica Teodoro.
         </p>
         
         <div className="content-row">
            <div className="main-column">
            <p className="paragraph-content">
    No conjunto de opúsculos traduzido pelo medievalista, encontram-se ensinamentos que vão desde os usos do Pai-Nosso e as formas de reconhecer as crenças condenáveis pela Igreja até as práticas devocionais, como o bom uso do tempo a Deus, entre outros. O hábito de rezar o Pai-Nosso e outras preces, como a Ave-Maria e o Credo, deveria fazer parte do cotidiano de todo cristão, mas não somente a sua repetição. Como mostra a <em>Glosa do Pai-Nosso (Glosa del Pater Noster), </em>composta em fins do século XIII, por D. Pedro, bispo de Jaén, além da memorização, era necessário que os fiéis compreendessem a doutrina manifestada na oração, como o arrependimento dos pecados e a importância das ações devocionais – em especial, à Eucaristia. “Cada parte dessa prece era explicada de maneira simples, para que os conversos e cristãos não apenas aprendessem a memorizá-la, mas também a reconhecer o valor do conteúdo nela expresso”, esclarece Teodoro na introdução.   
</p>
<p className="paragraph-content">
              Além das virtudes e dos benefícios da prece do Pai-Nosso, os fiéis deviam ser capazes de diferenciar as falsas crenças – taxadas de supersticiosas 
               ou heréticas –, perigosas à salvação, das crenças verdadeiras ensinadas pela Igreja. Essa intenção, também presente na <em>Glosa do Pai-Nosso </em> em relação às crenças de judeus e mouros, é bastante explícita no <em>Contra os fados, </em>um tratado do século XIV, escrito, provavelmente, por Alfonso de Valladolid (1270-1346), um judeu convertido, que refuta o determinismo astrológico e a crença em fados (destino, sorte), ressaltando a doutrina do livre-arbítrio. “A obra <em>Contra os fados </em> procurava, portanto, convencer o fiel de que ele seria responsável por seu futuro, e não os astros ou qualquer outra força que não proviesse de Deus”, argumenta o medievalista no texto introdutório da obra.   
</p>   

   
</div>
            <div className="quote-column">
              <blockquote>
                <p className='citacaoartigo'>
                “No mundo castelhano do final da Idade Média, há
uma variedade grande de obras sobre os costumes.
Não tive a pretensão de mapear o que pudesse ser
a totalidade, mas apresentar uma amostra, ou seja, alguns textos representativos de cada gênero que ajudou a fixar costumes cristãos”.
                </p>
                
              </blockquote>
             
</div>
 </div>

 <p className="paragraph-content">
 As crenças mundanas é outro assunto abordado nos escritos voltados para a catequese dos fiéis. No maior tratado que compõe a antologia, o <em>Espelho da alma </em>  Fr. Lope Fernández de Minaya alerta para as falsas garantias dos prazeres do mundo, como os deleites carnais, as riquezas e as honras, além de mostrar um roteiro de comportamentos desejáveis aos cristãos. Teodoro também identifica o <em>Tratado breve de penitência </em>como um prolongamento do <em>Espelho da alma, </em> peça que, até então, era tida como uma obra independente

 </p>

 <div className="section-title">
         
       <h2>LEIGOS E CLÉRIGOS NA MIRA DA IGREJA</h2>
     
      </div>
      <p className="paragraph-normal">
      A educação cristã em terras castelhanas visava instruir, principalmente, os cristãos leigos, de todas as condições sociais, gêneros e idades. Alguns grupos, no entanto, são mencionados nessa documentação traduzida por Teodoro. É o caso, por exemplo, da <em>Breve doutrina e ensinança, </em>  de D. Fr. Hernando de Talavera, publicada em Granada, em 1496, cuja intenção catequética de moços e moças é o objetivo do autor.
</p>

<p className="paragraph-content">
Em outra obra da mesma coletânea do bispo de Granada, chamada <em>Proveitoso tratado de como devemos haver muito cuidado em expender mui bem o tempo, </em> o mesmo autor apresenta inúmeros conselhos a Maria Pacheco, condessa de Benavente, sobre como se deve usar o tempo a favor das atividades devocionais. Segundo o historiador, na introdução, ainda que o tratado fosse destinado a uma nobre do reino, seu uso não se restringiu a ela. “Por se tratar de um opúsculo impresso junto com outras obras reunidas para a promoção de uma formação cristã geral, contemplando diversas esferas da vida do fiel, o texto poderia servir para alimentar a fé de outras pessoas, e não apenas de um destinatário em especial. Outros poderiam se colocar no lugar de Maria Pacheco e aprender a avaliar a maneira como despendiam seu tempo e se dedicavam às ações votivas”.
</p>
<p className="paragraph-content">
Nesse contexto, não somente os leigos estiveram no rol das preocupações pastorais da Igreja. O despreparo dos clérigos diocesanos e o relaxamento dos monges também inquietaram os prelados desse tempo. Como mostra Teodoro, as constituições sinodais do bispo de Leão D. Gonzalo Osorio, de 1303, além de buscarem moralizar os quadros eclesiásticos hispânicos, ofereceram orientações de como os clérigos deveriam proceder na administração de sacramentos – em especial, a confissão – e nas pregações feitas aos fiéis nas missas – a respeito dos artigos da fé (contidos no <em>Credo</em>), dos dez mandamentos, dos pecados a serem guardados e das boas obras a serem praticadas.
</p>
<div className="contentDivulgacao">  
        <div className="book-image">
        <img src={Imagem_artigoIII} alt="Capa do Livro" style={{ width: '500px', height: 'auto' }} />   
        </div>
          <div className="text-contentDivulgacao">
          <p><em>“E há outros sábios que dizem que todo mal que os homens passam, vem por virtude do signo, do planeta, ou do dia ou da noite em que nasceram”. </em>
           Essa passagem, que refuta a crença de que os astros interfeririam na vida das pessoas, faz parte do tratado <em>Contra os fados, </em>um dos opúsculos traduzidos
          na antologia. imagem: fólio 197r, do Contra os fados, conservado no ms. h.iii.3, madri, biblioteca do monastério de El Escorial (Espanha).
          </p>

</div> </div>
 <br></br>



 <div className="section-title">
         
         <h2>PRIMEIRO VOCABULÁRIO DE AÇÕES VIRTUOSAS EM CASTELHANO</h2>
       
        </div>

        <p className="paragraph-normal">
        Os opúsculos escolhidos para a antologia fazem parte dos primeiros registros da pastoral compostos em castelhano. A produção de textos que introduziam as bases da vida cristã em língua vernácula, intensificada depois do Concílio de Valladolid, de 1322, facilitava a comunicação com os fiéis e os religiosos, já que, segundo o historiador, 
        era a língua corrente em muitas regiões ibéricas desse momento. “São textos escritos para serem memorizados e circularem oralmente; alguns foram elaborados para clérigos poderem admoestar os fiéis. Em linhas gerais, esses textos ajudaram a construir o primeiro vocabulário de ações virtuosas em castelhano”, explica Teodoro. As obras impressas de Talavera, no fim do século XV, igualmente mostram uma demanda de leitores por esse tipo de literatura, que podiam ser clérigos, mas também leigos letrados.
</p>
<p className="paragraph-content">

A obra, que acaba de chegar ao público de língua portuguesa, possibilitará ao leitor ter contato com uma parte dos documentos catequéticos da Idade Média, assim como conhecer aspectos das ações pastorais da Igreja e dos monarcas castelhanos naquele período.
         </p>



         <div className="content-columns-artigoII">
  <div className="column-artigoII">
    <h3 id='h3-artigoII'>Projetos</h3>
    

    <p>
    » O ensino da fé cristã na península Ibérica, sécs. XIV, XV e XVI 
    (FAPESP n. 1711111-9). Modalidade: Jovem Pesquisador. Pesquisador 
    responsável: Leandro Alves Teodoro (Unesp-Assis).
    </p>
  </div>
    
  
  
</div>

<p>  <strong>  Patrícia Serieiro.</strong> Bolsista de Jornalismo Científico-IV/ FAPESP (Processo 2024/01457-9).</p>


          <div className="pdf-tbn-evento">
            <a href="https://bucket.server.umahistoriadapeninsula.com/artigos/ResenhaIII.pdf" target="_blank" class="btn btn-main mt-20">Baixar Arquivo</a>                      
     </div>

 <footer className="footerDivulgacao">
          <p>O ENSINO DA FÉ CRISTÃ NA PENÍNSULA IBÉRICA (SÉCS. XIV, XV E XVI)</p>
          <p> Setembro de 2024</p>
        </footer>

        </main>
     
     </div>   
    <Footer/>
    </div>
  );
}

export default ArtigoIII;
 