import React from 'react';
 import navIcon7 from '../assets/img/email.png'
import navIcon9 from '../assets/img/link.png'
 

  

function CardItem(props) {

   return (
    <> 
      <li className='cards__item'>
        
      {/* <Link className='cards__item__link' to={props.path}  >  */}
      {/* <a
          href={props.path}
          className="cards_item_link"
          target="_blank"
          rel="noopener noreferrer"
        >   */}

           
        <div  className='cards__item__link'>  
     
           <figure className='cards__item__pic-wrap' data-category={props.label}>  
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.src}
              path={props.path}
             
            />
          </figure>
 
          <div className='cards__item__info'>
            <h5 className='cards__item__name'>{props.name}</h5>
            <h5 className='cards__item__subtitle'>{props.subtitle}</h5>
            <h5 className='cards__item__text'>{props.text}</h5>
           
           <div  className='cards__item__a'>  
            <a className="btn btn-visualizar-pdf-main mt-10" target="_blank" href={props.path} >
            <span > <img src={navIcon7} id="icons" /> </span>
            </a>
            <a className="btn btn-visualizar-pdf-main mt-10" target="_blank" href={props.pathb} >
            <span > <img src={navIcon9} id="icons" /> </span>
            </a>
            </div>

          </div>
          </div>
          {/* </a> */}
        {/* </Link> */}
       
 


      </li>
    </>
  );
}

export default CardItem;
