import React from 'react';
import './Css/CardsEventos.css';
import CardsItemEventos from './CardsItemEventos';
 

function CardsEvento() {
    return (
      <div className='cards_evento'>
        <h1> </h1>
        <h2> </h2>
        <div className='cards__container_evento'>
          <div className='cards__wrapper_evento'>
            <ul className='cards__items'>

            <CardsItemEventos  
                src='https://bucket.server.umahistoriadapeninsula.com/eventos/VIEvento.jpg'
                name='VI Evento Internacional '
               text=' VI Evento Internacional - Instrução e a persuasão dos exempla dias 4, 5 e 6 de Junho' 
              label='Visitar'
              path='/VIEvento'
               />


            <CardsItemEventos  
                src='https://bucket.server.umahistoriadapeninsula.com/eventos/Vevento.jpeg'
                name='V Evento Internacional '
               text=' V Evento Internacional - Práticas de devoção no mundo Ibérico medieval
              29 e 30 de Agosto de 2023' 
              label='Visitar'
              path='/VEvento'
               />
  
  
<CardsItemEventos  
                src='https://bucket.server.umahistoriadapeninsula.com/eventos/IV.jpeg'
                name='IV Evento Internacional
                Ética e formação moral do exemplum'
               text=' IV Evento Internacional - Ética e Formação moral do Exemplum
              9,10 e 11 de Agosto de 2022' 
              label='Visitar'
              path='/IVEvento'
               />
              

               <CardsItemEventos
                src='https://bucket.server.umahistoriadapeninsula.com/eventos/cursopaleografia.jpeg'
                name='Curso de Introdução à Paleografia Ibérica Medieval e Moderna'
                text='Curso de Introdução à Paleografia Ibérica Medieval e Moderna'
                label='Visitar'
                path='/Paleografia'
              />

 </ul>

            <ul className='cards__items'>
             
            <CardsItemEventos
                src="https://bucket.server.umahistoriadapeninsula.com/eventos/III.jpeg"  
                name='III Evento Internacional
                Aconselhar e prescrever em terras cristãs'
                text='III Evento Internacional- Aconselhar e prescrever em terras cristãs
              20,21,22 e 23 de setembro de 2021'
                label='Visitar'
                path='/IIIEvento'
              />
            
<CardsItemEventos
                src='https://bucket.server.umahistoriadapeninsula.com/eventos/II.jpeg'
                name='II Evento Internacional
                A FORMAÇÃO DE REINOS VIRTUOSOS NA IDADE MÉDIA'
                text='Evento realizado nos dias 07, 08 e 09 de Outubro de 2020, evento realizado virtualmente com a presença de 20 campi e 59 participantes.'
                label='Visitar'
                path='/IIEvento'
              />
               <CardsItemEventos
                src='https://bucket.server.umahistoriadapeninsula.com/eventos/ADevocao.jpeg'
                name='A devoção popular na Idade Média'
                text='Palestra do Prof. Dr. Jean-Claude Schmitt na UNESP/Câmpus de Franca.'
                label='Visitar'
                path='/Adevolucao'
              />

<CardsItemEventos
                src='https://bucket.server.umahistoriadapeninsula.com/eventos/I.jpeg'
                name='I Seminário do Grupo “O Ensino da Fé Cristã na Península Ibérica (sécs. XIV e XV)"'
                text='Realizado nos dias 23 e 24 de Setembro de 2019, no auditório Fausto Castilho no IFCH / Unicamp.'
                label='Visitar'
                path='/ISeminario'
              />
            </ul>
          </div>
        </div>
      </div>
    );
  }
  
  export default CardsEvento;

  