import React from 'react';
import { Link } from 'react-router-dom';
import navIcon7 from '../assets/img/email.png'
import navIcon9 from '../assets/img/link.png'
 

  

function CardsItemEventos(props) {

   return (
    <> 
      <li className='cards__item'>
  
       <Link className='cards__item__link_evento' to={props.path}  >  
      
        <div  className='cards__item__link'>  
  
           <figure className='cards__item__pic-wrap' data-category={props.label}>  
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.src}
              path={props.path}
              
            />
          </figure>

          <div className='cards__item__info'>
            <h5 className='cards__item__name'>{props.name}</h5>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
          </div>
         </Link>  
       
 


      </li>
    </>
  );
}

export default CardsItemEventos;
