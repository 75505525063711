import React from 'react';
import './Css/Cards.css';
import CardItem from './CardItem';
 
 
   

function CardsTeste() {
  return (
    <div className='cards'>
      <h1 className='title-page'>Parceiros</h1>
      <h2 className='subtitle-page'>Insituições Parceiras</h2>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/Parceiro1.jpg'
              text='GAHOM-AHLOMA - Groupe d`Anthropologie Historique de l`Occident médiéval '
               
                            label='Visitar'
                            path='http://ahloma.ehess.fr/'
                            pathb='http://ahloma.ehess.fr/'
            />
            <CardItem
              src='images/Parceiro2.jpg'
              text='Université de Fribourg'
              label='Visitar'
            

              path="mailto:umahistoriadapeninsula@gmail.com"
              pathb='https://www.snf.ch/en'
            />
          </ul>
          
        </div>
      </div>



   
         
          
         
     
    </div>
  );
}

export default CardsTeste;
