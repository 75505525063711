import React, { useEffect, useReducer, useState } from "react";

import CardItem from "./CardItemBD";
import CardItemBDBteste from "./CardItemBDBteste";

import Spinner from "react-bootstrap/Spinner";
import Counter from "../BDSerachMark/Counter";
import SearchBar from "../BDSerachMark/SearchBar";

import "./CardsBd.css";

const initialState = {
  isLoading: true,
  data: [],
  search: "",
  searchData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case "SEARCH_INPUT":
      return { ...state, search: action.payload };
    case "SEARCH_DATA":
      return { ...state, searchData: action.payload };
    default:
      throw new Error();
  }
};

const CardsVerbetes = () => {
  //*********************** carregamento 4 em 4  */
  const [visibleCount, setVisibleCount] = useState(4); // Começa mostrando 4 cards
  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };
  //*********************** carregamento 4 em 4  */
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchData = async (url) => {
    try {
      const res = await fetch(url);
      const data = await res.json();
      dispatch({ type: "SET_DATA", payload: data });
    } catch (err) {
      console.log("Error:", err);
    }
  };

  useEffect(() => {
    fetchData("https://siteapi.umahistoriadapeninsula.com/api/umahistoria/");
  }, []);

  function removerSpecials(texto) {
    // eliminando acentuação
    texto = texto.replace(/[ÀÁÂÃÄÅ]/, "A");
    texto = texto.replace(/[àáâãäå]/, "a");
    texto = texto.replace(/[ÈÉÊË]/, "E");
    texto = texto.replace(/[Ç]/, "C");
    texto = texto.replace(/[ç]/, "c");
    return texto.replace(/[^a-z0-9]/gi, "");
  }
  const handleInput = (e) => {
    let str = e.target.value;
    dispatch({ type: "SEARCH_INPUT", payload: str });
    const newArr = state.data
      .filter(
        (item) =>
          item.title.toLowerCase().includes(str.toLowerCase()) ||
          item.description.toLowerCase().includes(str.toLowerCase()) ||
          item.palavrachave.toLowerCase().includes(str.toLowerCase()) ||
          item.textoverbete.toLowerCase().includes(str.toLowerCase()) ||
          item.autor.toLowerCase().includes(str.toLowerCase())

        //   item.title.toLowerCase().includes(removerSpecials(str.toLowerCase())) ||
        //   item.description.toLowerCase().includes(removerSpecials(str.toLowerCase())) ||
        // item.palavrachave.toLowerCase().includes(removerSpecials(str.toLowerCase())) ||
        //   item.textoverbete.toLowerCase().includes(removerSpecials(str.toLowerCase())) ||
        //   item.autor.toLowerCase().includes(removerSpecials(str.toLowerCase()))
      )
      .map((item) => {
        let newTitle = item.title.replace(
          new RegExp(str, "gi"),
          (match) =>
            `<mark style="background: #fed136; color: white;">${match}</mark>`
        );

        let newDescription = item.description.replace(
          new RegExp(str, "gi"),
          (match) =>
            `<mark style="background: #fed136; color: white;">${match}</mark>`
        );
        let newpalavrachave = item.palavrachave.replace(
          new RegExp(str, "gi"),
          (match) =>
            `<mark style="background: #fed136; color: white;">${match}</mark>`
        );
        let newtextoverbete = item.textoverbete.replace(
          new RegExp(str, "gi"),
          (match) =>
            `<mark style="background: #fed136; color: white;">${match}</mark>`
        );
        let newautor = item.autor.replace(
          new RegExp(str, "gi"),
          (match) =>
            `<mark style="background: #fed136; color: white;">${match}</mark>`
        );

        return {
          ...item,
          title: newTitle,
          description: newDescription,
          palavrachave: newpalavrachave,
          textoverbete: newtextoverbete,
          autor: newautor,
        };
      });

    dispatch({ type: "SEARCH_DATA", payload: newArr });
  };

  return (
    <div className="cards_BD">
      <SearchBar onInput={(e) => handleInput(e)} />

      <div className="cards__container_BD">
        <div className="cards__wrapper_BD">
          <ul className="cards__items_BD">
            <Counter
              result={
                state.searchData.length > 0 &&
                state.searchData.length < state.data.length
                  ? state.searchData.length
                  : null
              }
            />
            <table class="table table-bordered ">
              <thead></thead>
            </table>
            {state.isLoading ? (
              <div className="divspinner">
                {/* <p className='pcarregando'>Carregando...</p>   */}
                <Spinner animation="border" variant="warning" />
              </div>
            ) : state.search.length > 0 ? (
              state.searchData.map((props) => (
                //Resulado
                <CardItemBDBteste
                  key={props.id}
                  title={props.title}
                  description={props.textoverbete}
                  imgcard={props.imgcard}
                  linkid={props.id}
                  autor={props.autor}
                />
              ))
            ) : (
              state.data.slice(0, visibleCount).map((props) => (
                //state.data.map(props => (
                <CardItem
                  key={props.id}
                  title={props.title}
                  // description={props.description}
                  imgcard={props.imgcard}
                  linkid={props.id}
                  //autor={props.autor}
                />
              ))
            )}
          </ul>
          <button
            className="btn btn-verbete-main-verbete mt-10"
            style={{
              display: "block",
              margin: "0 auto",
            }}
            onClick={loadMore}
          >
            {" "}
            Carregar Mais Verbetes
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardsVerbetes;
