import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
// import './Counter.css'


const useStyles = makeStyles({
  counter: {
    height: '2.5rem',
    margin: '1.2rem',
    textAlign: 'center',
    background: '#fed136', 
    position: 'center', 
    padding: '1px 100px',
    texttransform: 'uppercase',
    margintop: '0rem',
    marginright: '6px',

  },
  type: {
    lineHeight: '2.5rem',
    fontWeight: '600',
    color: 'whitesmoke',
    
  },
 
})

const Counter = props => {
  const classes = useStyles()

  return (
    <Card className={classes.counter}>
      <Typography color="textSecondary" gutterBottom className={classes.type}>
      

         {props.result} Resultados da busca
       
      </Typography>
    </Card>
  )
}

export default Counter
