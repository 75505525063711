import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import navIcon1 from "../../assets/img/bookmark.png";
import navIcon3 from "../../assets/img/download-nuvem.png";
import navIcon2 from "../../assets/img/open-book.png";
import navIcon4 from "../../assets/img/palavra-chave.png";

import { useParams } from "react-router-dom";
import "./VerbetesIndex.css";

import Footer from "../Footer";

const verbetessURL =
  "https://siteapi.umahistoriadapeninsula.com/api/umahistoria/";

const VerbetesIndex = (props, showLink = true) => {
  const createMarkup = (html) => {
    return { __html: html };
  };

  const { id } = useParams();
  const [verbete, setVerbete] = useState(null);

  const getVerbete = async (url) => {
    const res = await fetch(url);
    const data = await res.json();
    console.log(data);
    setVerbete(data);
  };

  useEffect(() => {
    const verbeteUrl = `${verbetessURL}${id}`;
    getVerbete(verbeteUrl);
  }, []);

  return (
    <div>
      <div class="VrbetesIndex">
        <div class="row">
          <div class="col-md-12">
            <h1>O ensino da fé cristã na Península Ibérica.</h1>
          </div>
        </div>
      </div>

      <div>
        {verbete && (
          <section class="verbetesIndex-section" id="">
            <div class="container">
              <div className="row">
                <div className='class="col-lg-12"'>
                  <div class="post-content text-center">
                    <h1 className="titulo"> {verbete.title}</h1>
                    <h2 className="autor">
                      {verbete.autor} - {verbete.instituicaoautor}{" "}
                    </h2>
                  </div>
                </div>
                <div className="div-button">
                  {/* <div className="col-xs-12 col-md-6 text-justify p-2 m-0"> */}
                  <a
                    href={verbete.linkpdfverbete}
                    target="_blank"
                    class="btn "
                    className="baixarLink"
                    rel="noreferrer"
                  >
                    <img src={navIcon3} alt="" id="icon" /> Baixar o Verbete
                  </a>
                  {/* </div> */}

                  {showLink && (
                    <Link
                      className="btn btn-visualizar-pdf-main mt-10"
                      to={`/VerbetesPDF/${verbete.id}`}
                    >
                      Visualizar Documento
                    </Link>
                  )}
                </div>
                <div className="col-xs-12  ">
                  <p
                    className="description"
                    dangerouslySetInnerHTML={createMarkup(verbete.textoverbete)}
                  />
                </div>

                {/* <div className="col-xs-12 col-md-6  text-justify text-native bg-light p-2 m-0"> */}

                <div className="  text-justify text-native bg-light p-2 m-0">
                  <p
                    className="description"
                    dangerouslySetInnerHTML={createMarkup(verbete.descriptionB)}
                  />
                </div>
                <div class="col-xs-12   col-md-12  text-right">
                  <h5 class="">
                    <b>{verbete.autor}</b>{" "}
                  </h5>
                  <h5 class="">
                    <b>{verbete.instituicaoautor}</b>{" "}
                  </h5>
                </div>

                <div class="col-xs-12   col-md-12  text-wight">
                  <img src={navIcon4} alt="" id="icon" />{" "}
                  <b> Palavras-chave:</b>
                  <p className="ptrecho">{verbete.palavrachave} </p>
                </div>

                <div class="col-xs-12 col-md-12 text-center">
                  <h1 class="section-heading m-4">
                    <img src={navIcon2} alt="" id="icon" /> Edições Modernas
                  </h1>
                  <p
                    className="pedicoes"
                    dangerouslySetInnerHTML={createMarkup(verbete.edicoes)}
                  />
                </div>

                <div class="col-xs-12 col-md-12 text-center">
                  <h1 class="section-heading m-4">
                    <img src={navIcon1} alt="" id="icon" /> Trecho traduzido e
                    modernizado
                  </h1>
                </div>

                <div>
                  <p
                    className="ptrecho"
                    dangerouslySetInnerHTML={createMarkup(verbete.trecho)}
                  />
                  <p
                    className="ptrechoB"
                    dangerouslySetInnerHTML={createMarkup(verbete.trechoB)}
                  />
                </div>

                <div className="row entry-details">
                  <div className="col-xs-12  col-md-12 text-left">
                    <div className="post-content p-1 m-0">
                      <blockquote data-aos="fade-left" data-aos-duration="1000">
                        <div className="descricaoVerbete">
                          <span>
                            <p className="descricaoVerbeteP">
                              {" "}
                              <b> Autor do documento: </b>
                              {verbete.autorcomp}
                            </p>{" "}
                          </span>

                          <span>
                            <p className="descricaoVerbeteP">
                              <b> Nome do documento:</b> {verbete.nomedoccomp}{" "}
                            </p>{" "}
                          </span>
                          <span>
                            <p className="descricaoVerbeteP">
                              <b> Data da composição:</b> {verbete.datacomp}
                            </p>{" "}
                          </span>
                          <span>
                            <p className="descricaoVerbeteP">
                              <b> Lugar de composição ou impressão:</b>{" "}
                              {verbete.localcomp}
                            </p>{" "}
                          </span>
                          <span>
                            <p className="descricaoVerbeteP">
                              <b>Imagem:</b> {verbete.imgcomplink}
                            </p>{" "}
                          </span>
                        </div>
                      </blockquote>
                    </div>
                  </div>
                </div>

                <div class="row ">
                  <div class="col-xs-12  col-md-12 text-center">
                    <figure className="">
                      <img
                        className="imgverbete"
                        alt="Travel Image"
                        src={verbete.srcimgverbete}
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default VerbetesIndex;
