import React from 'react';
import './EventoSelecionados.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';


export default function Adevolucao() {
    return (
        <div>
            <div class="TituloEventosSelecionados">
                <div class="row">
                    <div class="col-md-12">
                         
                        <h1>A devoção popular na Idade Média</h1>
                        <h2>O ensino da fé cristã na Península Ibérica.</h2>
                    </div>
                </div>
            </div>

            <div className='EventosMov'>
                <div class="container" id='TituloEventosSelecionados'>
                   
                    <div class="row">
                        <div>
                            <div className="titulo-evento">
                                <h1>Palestra do Prof. Dr. Jean-Claude Schmitt na UNESP/Câmpus de Franca.

</h1>
                                <div class="border"></div>
                            </div>
                        </div>
 

                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )

}
