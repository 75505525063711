
import React, { useState } from 'react';
import './EventoSelecionados.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';
import ImagemVEvento from '../../assets/img/PostVEvento.jpg'
import emailjs from '@emailjs/browser'

import './VEvento.css'



export default function Vevento() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [universidade, setUniversidade] = useState('')
    const [escolaridade, setEscolaridade] = useState('')
    const [telefone, setTelefone] = useState('')

    function sendEmail(e) {
        e.preventDefault()

        if (name === "" || email === '' || universidade === '' || escolaridade === '' || telefone === '') {
            alert('preencher todos os campos')
        }

        const templateParams = {
            from_name: name,
            from_universidade: universidade,
            from_email: email,
            from_escolaridade: escolaridade,
            from_telefone: telefone
        }
        emailjs.send('service_tis1i34', 'template_bpd1gue', templateParams, 'ec2gpA3rZ_45WFFM9')
            .then((response) => {
                console.log('email enviado', response.status, response.text)
                setName('')
                setEmail('')
                setUniversidade('')
                setEscolaridade('')
                setTelefone('')
            }, (err) => {
                console.log("Erro", err)
            })
        }
        return (
            <div>
                <div class="TituloEventosSelecionados">
                    <div class="row">
                        <div class="col-md-12">
                            <h1>V Evento Internacional </h1>
                            <h2>PRÁTICAS DE DEVOÇÃO NO MUNDO IBÉRICO MEDIEVAL </h2>
                            <h2>O ensino da fé cristã na Península Ibérica.</h2>
                        </div>
                    </div>
                </div>
                
                <div className='VEventoMov'>

                    
                    <div class="container" id='TituloEventosSelecionados'>
                    
         

                    <div className='inscricoesBox'>  
                    
                    <div class="table-responsive">

                   
                    <table class="table table-bordered ">

                        
                        <thead>
                        <tr class="bg-dark text-white">
                            <th colspan="4" class="text-center">29 e 30 de agosto de 2023 (terça e quarta) UNESP – Câmpus de Assis</th>
                        </tr>
                        <tr class="bg-danger text-white">
                            <th colspan="4" class="text-center">Programação</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="bg-light">
                            <th scope="row" colspan="3" class="text-center bg-info">29/08/2023 (Terça-feira)</th>
                        </tr>
                        <tr>
                            <th scope="row" colspan="1" class="text-center"></th>
                            <th scope="row" colspan="1" class="text-center"></th>
                            <th scope="row" colspan="1" class="text-center"></th>
                        </tr>
                        <tr>
                            <th scope="row" class="text-center">09:30</th>
                            <td colspan="2" class="text-center">
                                Credenciamento
                            </td>
                        </tr>
                        
                        <tr>
                            <th scope="col" class="text-center">10:00 às 10:40</th>
                            <td>
                                <strong> Conferência 1: </strong> <i> Las prácticas devocionales entorno a la muerte en la Castilla medieval: testamentos y cofradías en la ciudad de Burgos (siglos XIII-XV)</i>
 


                            </td>
                            <td colspan="1">
                            Profa. Dra. Susana Guijarro González 
(Universidad de Cantabria)

                                <br></br>
                                <br></br>
                                <small> <strong>Mediador:</strong> Prof. Dr. Leandro Alves Teodoro 
                                    (Universidade Estadual Paulista – Campus de Assis)</small>
                            </td>

                        </tr>
              

                         
                        <tr>
                            <th scope="col" class="text-center">11:00</th>
                            <td colspan="2" class="text-center">
                                <strong>Almoço</strong>
                            </td>

                        </tr>

                        <tr>
                            <th scope="col" class="text-center">14:00 às 17:00


                            </th>
                            <td colspan="1">
                                <strong> Simpósio Temático: </strong>
                               <i>  Formas e experiências da devoção na Idade Média e no início da Idade Moderna</i>


                            </td>
                            <td colspan="1">

                                <strong>Organizadores: </strong>
                                André Silva Ranhel e Patrícia Antunes Serieiro Silva
 
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" class="text-center">20:00</th>
                            <td colspan="1" class="">
                                <strong>Mesa: </strong>A peregrinação e a devoção em Portugal
 
                            </td>
                            <td colspan="1" class="">
                                <strong><i>A territorialização da santidade: a distribuição das relíquias dos ‘Santos Mártires do Marrocos’ em Portugal (século XIII)</i></strong>
                               
                               <br></br> Profa. Dra. Ana Paula Tavares Magalhães Tacconi 
(Universidade de São Paulo)<br></br>
<br></br>
                           
                                <strong><i> Entre devoção e legitimação:  a trasladação para Lisboa e culto das relíquias de S. Vicente</i></strong>
                               
                               <br></br> Profa. Dra. Maria João Branco
(Universidade Nova de Lisboa)<br></br>
<br></br>
  
                                <small><strong> Mediador:</strong> Prof. Dr. Francisco José Diaz Marcilla (Universidad de Almería)</small>

                            </td>

                        </tr>

                        <tr>
                            <th scope="row" colspan="4" class="text-center bg-info text-white">30/08/2023 (Quarta-feira)</th>
                        </tr>
                        <tr>
                            <th scope="row" colspan="1" class="text-center"></th>
                            <th scope="row" colspan="1" class="text-center"></th>
                            <th scope="row" colspan="1" class="text-center"></th>
                        </tr>
                        <tr>
                            <th scope="row" class="text-center">10:00</th>
                            <td colspan="1"><strong>Conferência 2:</strong> <i>Devoção na Baixa Idade Média através das crónicas: uma visão peninsular</i>
                            </td>
                            <td colspan="1">
                            Prof. Dr. Francisco José Diaz Marcilla (Universidad de Almería)

                              <br></br>
                              <br></br>
                                <small> <strong> Mediadora:</strong> Mediadora: Profa. Dra. Maria João Branco (Universidade Nova de Lisboa)</small>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" class="text-center">11:00</th>
                            <td colspan="2" class="text-center">
                                <strong>Almoço</strong>
                            </td>

                        </tr>
                        

                        <tr>
                            <th scope="col" class="text-center">14:00 às 17:00</th>
                            <td colspan="1" class="">
                                <strong>Simpósio Temático: </strong> <i>Formas e experiências da devoção na Idade Média e no início da Idade Moderna </i>
                               
                            </td>
                            <td colspan="1" class="">
                            
                               <strong> Organizadores:</strong> André Silva Ranhel e Patrícia Antunes Serieiro Silva 

                            </td>

                        </tr>
  
                       
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12">
                                <h1 className='inscricoes'>Inscrições Encerradas</h1>

                            </div>
                
                        <div class="row">                      
                            <img class="img-fluid" src={ImagemVEvento} />
                        </div>




                         <div className='formularioDiv'>
                            

                            <div className="pdf-tbn-evento">
                                {/* <a href="http://www.inscricoes.fmb.unesp.br/index.asp?configurar=true&codEvento=14270" target="_blank" class="btn btn-main mt-20">Inscreva Se aqui</a>*/}
                              
                                
                                <a href="https://bucket.server.umahistoriadapeninsula.com/eventos/CadernodeResumosEvento2023.pdf" target="_blank" class="btn btn-main mt-20">Baixar Caderno de Resumos</a>
                              
                                  
                           
                            </div>


                            {/* <form className="form" onSubmit={(sendEmail)}>
                                <div class="form-outline mb-4">

                                    <label className='labeltxt'  htmlFor="nome">Nome: </label>
                                    <input type="text" id="name" name="name" placeholder="Digite seu nome completo" class="form-control" onChange={(e) => setName(e.target.value)}
                                        value={name} />

                                </div>
                                <div class="row mb-4">
                                    <div class="col">
                                        <label className='labeltxt' htmlFor="universidade">Instituição / Universidade:</label>

                                        <input type="text" id="universidade" class="form-control" name="universidade" placeholder="Digite sua Instituição / Universidade" onChange={(e) => setUniversidade(e.target.value)}
                                            value={universidade} />
                                    </div>
                                    <div class="col">

                                        <label className='labeltxt' htmlFor="escolaridade">Escolaridade:</label>
                                        <select id="escolaridade" name="escolaridade" required class="form-control" onChange={(e) => setEscolaridade(e.target.value)}
                                            value={escolaridade}>
                                            <option value="">Selecione uma opção</option>
                                            <option value="graduacao-incompleta">Graduação incompleta</option>
                                            <option value="graduacao-completa">Graduação completa</option>
                                            <option value="mestrado-incompleto">Mestrado incompleto</option>
                                            <option value="mestrado-completo">Mestrado completo</option>
                                            <option value="doutorado-incompleto">Doutorado incompleto</option>
                                            <option value="doutorado-completo">Doutorado completo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col">
                                        <label className='labeltxt' htmlFor="email">E-mail:</label>
                                        <input type="email" id="email" name="email" required class="form-control" placeholder="Digite seu e-mail" onChange={(e) => setEmail(e.target.value)}
                                            value={email} />
                                    </div>
                                    <div class="col">
                                        <label className='labeltxt' htmlFor="telefone">Telefone:</label>
                                        <input type="tel" id="telefone" name="telefone" pattern="[0-9]{10,11}" placeholder="Digite o numero do seu telefone " required class="form-control" onChange={(e) => setTelefone(e.target.value)}
                                            value={telefone} />
                                    </div>
                                </div>  
                                <div class="row mb-4">  
                                <button type="submit">Enviar</button>
                                </div>
                           </form> */}

                        </div>

                    </div>
                    </div>
                </div>
                <Footer />
            </div>
        )

    }


