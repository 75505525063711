import React from 'react';
import './EventoSelecionados.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';


export default function ISeminario() {
    return (
        <div>
            <div class="TituloEventosSelecionados">
                <div class="row">
                    <div class="col-md-12">
                        <h1>I Seminário do Grupo   </h1>
                        <h1> “O Ensino da Fé Cristã na Península Ibérica (sécs. XIV e XV)"</h1>
                        <h2>O ensino da fé cristã na Península Ibérica.</h2>
                    </div>
                </div>
            </div>

            <div className='EventosMov'>
                <div class="container" id='TituloEventosSelecionados'>
                   
                    <div class="row">
                        <div>
                            <div className="titulo-evento">
                                <h1>07,08 e 09 de Outubro de 2021</h1>
                                <h6>23/09 (segunda-feira) às 14h: Conferência "Imagens e História" - Prof. Dr. Jean-Claude Schmitt</h6>
                                <h6>24/09 (terça-feira) às 9h: Conferência "Imagens dos sonhos na Idade Média" - Prof. Dr. Jean-Claude Schmitt</h6>
                                <h6>24/09 (terça-feira) às 14h: Conferência "Ritmos sociais e imagens na Idade Média" - Prof. Dr. Jean-Claude Schmitt</h6>
                                <h6>24/09 (terça-feira) às 16h: Apresentação do banco de dados "Obras pastorais e doutrinárias da Península Ibérica" - Prof. Dr. Leandro Alves Teodoro</h6>
                                <div class="border"></div>
                            </div>
                        </div>

                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify"> </h2>
                           <h6> </h6>
                            <div class="href-item">
                             </div>
                             <iframe width="100%" height="225" src="https://www.youtube.com/embed/KjQv21DYgEg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                       
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify"> </h2>
                            <h6> </h6>
                            <div class="href-item">
                             </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/377vebpihKQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                        
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify"> </h2>
                            <h6> </h6>
                            <div class="href-item">
                             </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/snynZgat8vw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                        
                     
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )

}
