import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type securityCointextType = {
  user:
    | {
        token: string;
        nome: string;
      }
    | undefined;
  login: (token: string, nome: string) => void;
  logoff: () => void;
  getToken: () => string;
};

const Context = createContext<securityCointextType>({
  user: undefined,
  login() {},
  logoff() {},
  getToken: () => "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ZmYzY2IyNTY1NzhiZmFiY2YwZmQ4OSIsIm5vbWUiOiJhZG1pbiIsImlhdCI6MTY5NDY5NzgzMX0.VEjWZHvJskJ3SSjuuTSMPPc0pgp37C1Utt7cbOT38CY",
});

export function useSecurityContext(): securityCointextType {
  return useContext(Context);
}

const SecurityContext: FC<{ children?: ReactNode }> = ({ ...props }) => {
  const [localUser, setLocalUser] = useState<
    { token: string; nome: string } | undefined
  >(undefined);

  const getToken = (): string => {
    if (localUser) return localUser.token;

    const ls = localStorage.getItem("pdfIndexerInformation");

    if (ls) {
      const u = JSON.parse(ls);
      return u.token;
    }

    window.location.href = "/login";
    return "";
  };

  const login = async (token: string, nome: string) => {
    setLocalUser({ nome, token });
    window.location.href = "/";
  };

  const logoff = async () => {
    setLocalUser(undefined);
    window.location.href = "/login";
  };

  const value = {
    user: localUser,
    login,
    logoff,
    getToken,
  };

  useEffect(() => {
    if (localUser)
      localStorage.setItem("pdfIndexerInformation", JSON.stringify(localUser));
  }, [localUser]);

  useEffect(() => {
    if (window.location.pathname !== "/login") {
      if (!localUser) {
        const ls = localStorage.getItem("pdfIndexerInformation");

        if (ls) {
          const u = JSON.parse(ls);
          if (u) setLocalUser(u);
        } else window.location.href = "/login";
      }
    }
  }, []);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export default SecurityContext;
