import React from 'react';
import './Css/Footer.css';
 
import LogoUnicamp from '../assets/img/logo_unesp-assis.png'
import LogoFapesp from '../assets/img/fapesp.png'

import navIcon1 from '../assets/img/youtube.svg'
import navIcon2 from '../assets/img/twitter.svg'
import navIcon3 from '../assets/img/instagram.svg'
import navIcon4 from '../assets/img/face.svg'
function Footer() {
  return (



    <div className='footer-container'>
 

      <section class='social-media'>
     
      <div className='logos'>
      <img className="img-logo-fapesp" src={LogoFapesp} />
              <img className="img-logo-unicamp" src={LogoUnicamp} />
             
          </div>

        <div class='social-media-wrap'>
       
        <div class="col-sm-12 col-md-12 col-lg-12 text-center ">
        
       
          <small class='website-rights'>ESTE SITE NÃO EXPRESSA A OPINIÃO DA FAPESP. <br />

            <a href="https://umahistoriadapeninsula.com/" className="hrefFooter">umahistoriadapeninsula.com - </a>

            <a href="https://umahistoriadapeninsula.com/politicas-de-privacidade-e-termo-de-uso" className="hrefFooter">Políticas de Privacidade</a>
          </small>
         

         
          </div>
          <div className="div-social-icon">
          <div className="social-icon">
              <a href="https://www.youtube.com/channel/UCOqszOrTMrhmlUvWCKXiT0A"><img src={navIcon1} alt="" /></a>
              <a href="https://twitter.com/DaPeninsula"><img src={navIcon2} alt="" /></a>
              <a href="https://www.instagram.com/historiadapeninsula/"><img src={navIcon3} alt="" /></a>
              <a href="https://www.facebook.com/profile.php?id=61558268759636&locale=pt_BR"><img src={navIcon4} alt="" /></a>
            
            </div>
            </div>

          </div>
          
       
      </section>
    </div>
  );
}

export default Footer;
