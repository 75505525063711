import React from "react";
import { Link } from "react-router-dom";
import { useSecurityContext } from "../../context/SecurityContext.tsx";
import fetchApi from "../../lib/fetch/index.ts";
import "./CardDocumentos.css";
import navIcon9 from '../../assets/img/link.png'
 
import TextField from '@material-ui/core/TextField'
 

function CardItemDocumentos(props) {
    const context = useSecurityContext();
    const { id, page } = props;  

    return (
        <li className='cards__item_Docs'>
            <div className='cards__item__link_Docs'>
 
                    <img  className='cards_results__item__img_Docs'
                        title='PDF Viewer'
                        src={props.imgcard}
                    ></img>
                  
 
                <div className='cards__item__info_Docs'>
                    <h5 className='cards__item__name_Docs'>{props.title}</h5>
                    <h5 className='cards__item__text_Docs'>{props.author}</h5>
                    
                    <div className='cards_results__item__a_Docs'>
                        <a>
                            <span>
                                {props.showLink && (
                                    <Link className="btn btn-verbete-main-verbete mt-10"   to={`/DocumentosPdfView/${props.id}/${props.page}?title=${props.title}&author=${props.author}`}
                                    >Visualizar</Link> 
                                )}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default CardItemDocumentos;
