import React from 'react';
import Footer from '../components/Footer';
import './Sobre.css'
import CardsTime from '../components/CardsTime'
import CardsParceiros from '../components/CardsParceiros'
import ImagemSobre from '../assets/img/sobre-imge-text.jpeg'
 


import 'bootstrap/dist/css/bootstrap.min.css';


import { Container } from 'react-bootstrap';


export default function Sobre() {
  return (
    <div>
 
       
      <div class="TituloSobre">
            <div class="row">
                <div class="col-md-12">
                    <h1>Uma história da Península</h1>
                    <h2>Sobre o grupo</h2>
                </div>
            </div>
        </div>
       

      <section class="about-shot-info section-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mt-20">
                    <h1 className='title-page'>Sobre o Grupo</h1>
                    <p class="text-muted text-justify" id="fontText">
                        O grupo “O ensino da fé cristã na Península Ibérica (sécs. XIV, XV e XVI)” foi criado com a
                        finalidade de explorar os caminhos percorridos pela cúpula da Igreja para a fixação das
                        crenças católicas no reino de Portugal e na Coroa de Castela no final da Idade Média e
                        início dos tempos modernos. Envolvendo pesquisadores de diferentes universidades brasileiras
                        e do exterior, esse projeto investirá na organização de cursos específicos acerca dessa
                        temática, de eventos científicos e na criação de um banco de dados de obras pastorais. </p>
                    <p class="text-muted text-justify" id="fontText">
                        Financiado pelo auxílio Jovem Pesquisador da FAPESP (Processo 2017/11111-9) e
                        sediado na Universidade Estadual de Campinas,o projeto visa serializar a produção de
                        escritos doutrinários e místicos de Portugal e Castela e estimular desde o trabalho de
                        edição de fontes medievais e modernas até a elaboração de estudos dos modos do fazer crer,
                        isto é, dos jogos de ensino e aprendizagem da moral cristã.
                    </p>

                </div>
                <div class="col-md-6">
                <img class="img-fluid" src={ImagemSobre}/>
                </div>
            </div>
        </div>
    </section>



    <section class="page-section p-4" id="SectionBancoSobre">
        <div class="container">
            <div class="text-center">
            <h1 class="section-subheading" id="h1-titulo">Banco de Dados</h1>
                <h3 class="section-heading text-uppercase"  id="h3-titulo">Obras pastorais e doutrinárias do mundo ibérico</h3>
             </div>
            <div class="row mb-5 p-4 text-justify">
                <div class="col-lg-6">
                    <h4 class="text-center p-1" id="h4-titulo">Objetivo</h4>
                    <p id="fontText">
                        O banco de dados “Obras pastorais e doutrinárias do mundo ibérico” é o resultado de um trabalho
                        coletivo de serialização de livros manuscritos e impressos de diferentes gêneros, escritos ou
                        compilados em português ou castelhano, que possuíam como objetivo fixar em língua vernácula
                        diretrizes concernentes à aprendizagem de preceitos elementares do Cristianismo. Voltados para a
                        admoestação do clero ou mesmo para servirem de inspiração aos leigos, os escritos reunidos nesta
                        plataforma digital permitem analisar um panorama da ação pastoral da Igreja em diversas regiões
                        da
                        Península Ibérica do final da Idade Média e começo da Época Moderna. </p>

                </div>
                <div class="col-lg-6">
                    <h4 class="text-center p-1" id="h4-titulo">Estrutura</h4>

                    <p id="fontText">
                        O banco de dados é constituído por uma série de verbetes organizados de maneira alfabética. Cada
                        um
                        desses verbetes comenta uma obra específica, apresentando os seus alvos e direcionamentos
                        moralizantes, bem como um conjunto de palavras-chave que possam definir os principais objetivos
                        das
                        lições por ela prescritas. Além disso, todos os verbetes possuem uma entrada para se acessar uma
                        imagem e um trecho transcrito do documento comentado, a fim de que seja exposta, não apenas aos
                        especialistas da área, mas sobretudo ao grande público, a configuração de uma de suas páginas.
                    </p>
                </div>
            </div>
        </div>
    </section>



     

      {/*------> Cards Membros */}


       
       
          <CardsTime />
        
       
      <Container>

        {/*------> Cards Parceiros */}
        
          <div>
            <CardsParceiros />
          </div>
        
         

      </Container>
      <Footer />

    </div>
  )
}

