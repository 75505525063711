import React from 'react';
import { Link } from 'react-router-dom';
import navIcon7 from '../../assets/img/email.png'
import navIcon9 from '../../assets/img/link.png'
 import  './CardsBdBteste.css'

function CardItemBDBteste(props, showLink = true) {

  const createMarkup = html => {
    return { __html: html }
  }

   return (
    <> 
      <li className='cards_results__item_BD'>
        
           
        <div  className='cards_results__item__link_BD'>  
     
  <figure className='cards_results__item__pic-wrap-BD'>  
            <img
              className='cards_results__item__img_BD'
              alt='Travel Image'
              src={props.imgcard}
              path={props.path}
             
            />
          </figure> 

          <div className='cards_results__item__info_BD'>
            <h1 className='cards_results__item__name_BD'  dangerouslySetInnerHTML={createMarkup(props.title)}/>
            <h2 className='cards_results__item__subtitle_BD'>{props.subtitle}</h2>
            <h5 className='cards_results__item__text_BD' dangerouslySetInnerHTML={createMarkup(props.description)} />  
           <h5 className='cards_results__item__text_BD' dangerouslySetInnerHTML={createMarkup(props.autor)} />  

              
             
           <div  className='cards_results__item__a_BD'>  
                
            {/* <a className="card-button" target="_blank" href={props.link} > */}
            <a>
            <span > 
               {/* <img src={navIcon9} id="icons" />  */}
               {showLink && <Link className="btn btn-verbete-main-verbete mt-10"  to={`/Verbetes/${props.linkid}`}>Visualizar</Link>}
               {/* {showLink && <Link className="btn btn-verbete-main-verbete mt-10"  to={`/VerbetesPDF/${props.linkid}`}>Documento</Link>} */}

             </span>
            </a>
            </div>

          </div>
          </div>
          {/* </a> */}
        {/* </Link> */}
       
 


      </li>
    </>
  );
}

export default CardItemBDBteste;
