import React from 'react';
import Footer from '../components/Footer';
import './BancodeDados.css';
import CardsVerbetes from '../components/CardsBD/CardsVerbetes'
import AutoresLista from '../components/Autores/Autoreslista';


export default function BancodeDados() {
  return (
    <div>

      <div class="TituloBancodeDados">
        <div class="row">
          <div class="col-md-12">
            <h1>Obras pastorais e Doutrinárias</h1>
          </div>
        </div>
      </div>


       
 
      <section class="BancodeDados-section" id="">
 
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
 
             <CardsVerbetes />   
              
            </div>

            <div class="col-lg-4">
<AutoresLista />
</div>
            {/* <div class="col-lg-4">
              <div className='autores-bd'>  
              <h2 class="h2class">Autores</h2>
            

               <div className="widget-categories">
 
                <ul class="widget-categories-list">
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/adeline-rucquoi">Adeline Rucquoi</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/adrian-fernandez-gonzalez">Adrián Fernández González</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/alberto-ruiz-berdejo-beato">Alberto Ruiz-Berdejo Beato</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/americo-venancio-lopes-machado-filho">Américo Venâncio Lopes Machado Filho</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/ana-maria-e-silva-machado">Ana Maria e Silva Machado</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/ariadne-nunes">Ariadne Nunes</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/arturo-jimenez-moreno">Arturo Jimenéz Moreno</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/cesar-nardelli-cambraia">César Nardelli Cambraia</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/constance-carta">Constance Carta</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/david-nogales-rincon">David Nogales Rincón</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/douglas-mota-xavier-de-lima">Douglas Mota Xavier de Lima</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/fernando-gonzalez-munoz">Fernando González Muñoz</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/francisco-jose-diaz-marcilla">Francisco José Díaz Marcilla</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/guillermo-fernando-arquero-caballero">Guillermo Fernando Arquero Caballero</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/hugo-o.-bizzarri">Hugo O. Bizzarri</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/isabel-ilzarbe">Isabel Ilzarbe</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/joao-jose-alves-dias">João José Alves Dias</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/leandro-alves-teodoro">Leandro Alves Teodoro</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/leticia-goncalves-alfeu-de-almeida">Letícia Gonçalves Alfeu de Almeida</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/lisana-rodrigues-trindade-sampaio">Lisana Rodrigues Trindade Sampaio</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/manuel-ortuno-arregui">Manuel Ortuño Arregui</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/marcelo-pereira-lima">Marcelo Pereira Lima</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/maria-jesus-lacarra">María Jesús Lacarra</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/mariel-perez">Mariel Pérez</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/michelle-souza-e-silva">Michelle Souza e Silva</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/pablo-martin-prieto">Pablo Martín Prieto</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/patricia-antunes-serieiro-silva">Patrícia Antunes Serieiro Silva</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/paulo-alexandre-cardoso-pereira">Paulo Alexandre Cardoso Pereira</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/silvia-maria-perez-gonzalez">Silvia María Pérez González</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/simone-ferreira-gomes-de-almeida">Simone Ferreira Gomes de Almeida</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/susana-guijarro">Susana Guijarro González</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/thiago-henrique-alvarado">Thiago Henrique Alvarado</a>
                  </li>
                  <li>
                    <a href="https://umahistoriadapeninsula.com/autor/veronica-orazi">Veronica Orazi</a>
                  </li>

                </ul>  
                </div> 
              </div>
            </div> */}


          </div>

        </div>






    

      </section>
      <Footer />


    </div>

  );
}

 