import React, { useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom"

import './Autores.css'

// import autores from './autoresjso'
  
// const verbetessURL = 'http://localhost:3004/api/umahistoriaAutores/'
const verbetessURL = 'https://backautores.onrender.com/api/umahistoriaAutores'

const initialState = {
	isLoading: true,
	data: [],
	search: '',
	searchData: [],
  }
   
  const reducer = (state, action) => {
	switch (action.type) {
	  case 'SET_DATA':
		return {
		  ...state,
		  data: action.payload,
		  isLoading: false,
		}
	  case 'SEARCH_INPUT':
		return { ...state, search: action.payload }
	  case 'SEARCH_DATA':
		return { ...state, searchData: action.payload }
	  default:
		throw new Error()
	}
  }

function AutoresLista(props, showLink = true) {
	 
	const [state, dispatch] = useReducer(reducer, initialState)

	const fetchData = async url => {
	  try {
		const res = await fetch(url)
		const data = await res.json()
		dispatch({ type: 'SET_DATA', payload: data })
	  } catch (err) {
		console.log('Error:', err)
	  }
	}
  
	useEffect(() => {
	    fetchData('https://backautores.onrender.com/api/umahistoriaAutores')
		//fetchData('http://localhost:3004/api/umahistoriaAutores/')
  
	}, [])
	return (
		 
		<div>
			<section>

				<div className='autores-bd'>
					<h2 class="h2class">Autores</h2>

					<div class="widget-categories-autores">
 						{/* { autores.map(( index) => ( */}
 
 
 		{    state.data.map(props => (

       
							<div>
								<div className="inner">
									<p></p>
									<a href={state._id} >
	 
										{showLink && <Link className="btn btn-autor-main-autor mt-10" to={`/AutoresIndex/${props._id}`} >{props.autor}</Link>}
 

									</a>
								</div>
							</div>
						))}

					</div>
				</div>


			</section>

		</div>


	)
}


export default AutoresLista;
