import React from 'react';
import './EventoSelecionados.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';
import ImagemCurso from '../../assets/img/cursoPaleografia.jpeg'


export default function Paleografia() {
    return (
        <div>
            <div class="TituloEventosSelecionados">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Curso de Introdução à Paleografia</h1>
                        <h1>Ibérica Medieval e Moderna </h1>
                         <h2>O ensino da fé cristã na Península Ibérica.</h2>
                    </div>
                </div>
            </div>

            <div className='EventosMov'>
                <div class="container" id='TituloEventosSelecionados'>
                   
                    <div class="row">
                        <div>
                            <div className="titulo-evento">
                                <h1>08 de Agosto</h1>
                                <h6>O curso "Introdução à Paleografia Ibérica Medieval e Moderna" apresentará tipos de letras, bem como abreviações de manuscritos e impressos. Trata-se de orientar o aluno em seus primeiros contatos com o documento.</h6>
                                <h6>O curso será presencial, gratuito, ministrado em língua espanhola e com certificação.</h6>
            
                                 <div class="border"></div>
                            </div>
                        </div>

                     <img class="img-fluid" src={ImagemCurso}/>
                       
 
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )

}
