import React from 'react';
import './DivulgacaoCientifica.css'
 import Footer from '../components/Footer';
 import DivulgacaoCientificaComponent from '../components/DivulgacaoCientifica/DivulgacaoCientificaComponent'



export default function DivulgacaoCientifica() {
    return (
<div>

<div class="DivulgacaoCientifica">
    <div class="row">
        <div class="col-md-12">
            <h1>Divulgação Científica</h1>
             
        </div>
    </div>
</div>

<DivulgacaoCientificaComponent/>

<Footer/> 

</div>
    )
    }