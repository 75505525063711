import React from 'react';
import './EventoSelecionados.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';


export default function IIIEvento() {
    return (
        <div>
            <div class="TituloEventosSelecionados">
                <div class="row">
                    <div class="col-md-12">
                        <h1>III Evento Internacional  </h1>
                        <h1>Aconselhar e prescrever em terras cristãs</h1>
                        <h2>O ensino da fé cristã na Península Ibérica.</h2>
                    </div>
                </div>
            </div>

            <div className='EventosMov'>
                <div class="container" id='TituloEventosSelecionados'>
                   
                    <div class="row">
                        <div>
                            <div className="titulo-evento">
                                <h1>20,21,22 e 23 de setembro de 2021</h1>
                                <div class="border"></div>
                            </div>
                        </div>

                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">Abertura &amp; Mesa 1</h2>
                            <div class="href-item">
                                <a href="https://www.youtube.com/embed/H9oXYsBMJPk"></a>
                            </div>

                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/H9oXYsBMJPk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                       
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">MESA 2: RECOMENDAÇÕES PARA O FORTALECIMENTO DA ALMA - ACONSELHAR E PRESCREVER EM TERRAS CRISTÃS</h2>
                            <div class="href-item">
                                <a href="https://www.youtube.com/embed/1VdXflkRMaI"></a>
                            </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/1VdXflkRMaI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                        
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">MESA 3: PRESCRIÇÕES PARA AS MULHERES - ACONSELHAR E PRESCREVER EM TERRAS CRISTÃS</h2>
                            <div class="href-item">
                                <a href="https://www.youtube.com/embed/1VdXflkRMaI"></a>
                            </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/RiOgOehdDOc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                        
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">Mesa 4: Recomendações de São Tomás de Aquino - Aconselhar e prescrever em terras cristãs</h2>
                            <div class="href-item">

                            </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/8NNMOU4jEBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                       
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">Mesa 5: Prescrições para combater heresias e proteger a conduta eclesiástica</h2>
                            <div class="href-item">
                                <a href="https://www.youtube.com/embed/SHuv2PshWSo"></a>
                            </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/SHuv2PshWSo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                        
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">Mesa 6: Prescrições médicas -  Aconselhar e prescrever em terras cristãs</h2>
                            <div class="href-item">
                                <a href="https://www.youtube.com/embed/QU3veZol1dY"></a>
                            </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/QU3veZol1dY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                       
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">Mesa 7: Corrigir e disciplinar nos tempos modernos - Aconselhar e prescrever em terras cristãs</h2>
                            <div class="href-item">
                                <a href="https://www.youtube.com/embed/lMVt4a610RY"></a>
                            </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/lMVt4a610RY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                        <div>
                            <div className="pdf-tbn-evento">
                                <a href="https://bucket.server.umahistoriadapeninsula.com/eventos/IIIEvento_caderno-de-resumos.pdf" target="_blank" class="btn btn-main mt-20">Baixar Caderno de Resumos</a>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )

}
