import React, { useEffect } from 'react';
import './ArtigoI.css';
import img1 from '../../../assets/img/Artigos/Ensinar_a_fe_crista_na_peninsula_Iberica_medieval1.png'
import img2 from '../../../assets/img/Artigos/Ensinar_a_fe_crista_na_peninsula_Iberica_medieval2.jpg'
import img3 from '../../../assets/img/Artigos/Ensinar_a_fe_crista_na_peninsula_Iberica_medieval3.jpg'
import img4 from '../../../assets/img/Artigos/Ensinar_a_fe_crista_na_peninsula_Iberica_medieval4.jpg'
import img5 from '../../../assets/img/Artigos/Ensinar_a_fe_crista_na_peninsula_Iberica_medieval5.png'
import img6 from '../../../assets/img/Artigos/Logo do grupo.jpg'


import Footer from '../../../components/Footer';
import { colors } from '@material-ui/core';




const Ensinar_a_fe_crista_na_peninsula_Iberica_medieval = () => {

    useEffect(() => {
        // Envia o evento de visualização de página para o Google Analytics
        window.gtag('config', 'G-T3KBNL1ZLF', {
            page_path: 'https://www.googletagmanager.com/gtag/js?id=G-T3KBNL1ZLF'
        });
    }, []);


    return (
        <div>
            <div class="TituloArtigoI">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Divulgação Científica</h1>
                    </div>
                </div>
            </div>

            <div className="containerDivulgacao">
                <div className="book-image">
                    <img src={img5} alt="Capa do Livro"
                        style={{ width: '100%', maxWidth: '800px', height: 'auto' }}
                    />
                </div>
                <main className="main-contentDivulgacao">
                    <h1 className="titleDivulgacao">
                    </h1>
                    <p className="subtitleDivulgacao"> </p>
 
                    <div className="contentDivulgacao">
                        <div className="book-image" style={{ flex: '1', textAlign: 'center' }}>
                            <img src={img6} alt="Capa do Livro" style={{ width: '400px', height: 'auto', marginBottom: '10px' }} />
                           
                        </div>
                        </div>
                    <div className="contentDivulgacao">
                        <div className="book-image">
                        </div>
                        <div className="text-contentDivulgacao">
                            <p className="highlight-letter">
                                Nos reinos de Portugal e de Castela, entre os séculos XIII e XV, circularam obras de diversos gêneros que alertavam os cristãos
                                sobre os cuidados com o uso das palavras, tanto em momentos de solenidade – em casamentos ou tribunais, por exemplo – como em suas
                                atividades do dia a dia. Para garantir a salvação, o indivíduo, fosse eclesiástico ou laico, devia evitar uma série de faltas relacionadas
                                aos sentidos, mas, em especial, os pecados que envolvessem a língua, como a mentira, a murmuração, a difamação, a blasfêmia, o falso
                                testemunho, entre outros. Ao mesmo tempo, esses textos estimulavam as pessoas a dizerem a verdade, bem como a fazerem outros usos
                                virtuosos das palavras, como a confissão, a oração, a pregação, o louvor etc. O caminho para o Paraíso dependia, portanto, do disciplinamento da palavra.

                            </p>
                            <p className="paragraph-content ">
                                A esse cenário, se dedicou o historiador Leandro Alves Teodoro, professor de História Medieval na Faculdade de Ciências e Letras da
                                Universidade Estadual Paulista (UNESP), Câmpus de Assis, em sua tese de livre docência, intitulada “A emenda da língua no mundo ibérico: as palavras de advogados, feiticeiros e padres (sécs. XIII-XV)”.
                                O estudo é o principal produto individual de conclusão do projeto “O ensino da fé cristã na península Ibérica (sécs. XIV, XV e XVI)”,
                                financiado na modalidade auxílio Jovem Pesquisador, pela Fundação de Amparo à Pesquisa do Estado de São Paulo (FAPESP), no qual
                                Teodoro foi o pesquisador responsável.
                            </p>
                            <p className="paragraph-content ">
                                Na mesma documentação, foram identificados também pelo historiador três tipos sociais frequentemente relacionados aos pecados da língua:
                                o advogado, o feiticeiro e o sacerdote. “A tese procurou focar no papel da palavra como veículo de aprendizagem nos mundos ibéricos medievais.
                                Para desenvolver o estudo, foquei três personagens: o advogado, o feiticeiro e o padre. São figuras bastante críticas no período,
                                que são importantes para examinar a emenda da língua e as funções atribuídas à palavra no cotidiano”, explica Teodoro.
                                O advogado, por utilizar, de modo proposital, uma fala difícil e falseada; o feiticeiro ou adivinho, por usar palavras para invocar forças
                                malignas; por fim, o sacerdote, condutor da palavra sagrada, pelo empenho que deveria ter no combate aos pecados da boca nas cerimônias e
                                pregações, fixando, assim, modelos de comportamentos e palavras virtuosas para os fiéis.
                            </p>
                        </div>
                    </div>

                    <div className="contentDivulgacao">
                        <div className="book-image" style={{ flex: '1', textAlign: 'center' }}>
                            <img src={img4} alt="Capa do Livro" style={{ width: '400px', height: 'auto', marginBottom: '10px' }} />
                            <p style={{ marginTop: '10px' }}>
                                Leandro Alves Teodoro, pesquisador responsável do projeto Jovem Pesquisador.
                            </p>
                        </div>

                    </div>
                    <div className="contentDivulgacao">

                        <div className="text-contentDivulgacao">
                            <div className="section-title">
                                <h2>ESTABELECIMENTO E DESENVOLVIMENTO DO PROJETO</h2>
                            </div>

                            <p className='paragraph-normal'>
                                O projeto “O ensino da fé cristã na península Ibérica (sécs. XIV, XV e XVI)”, iniciado em outubro de 2018,
                                originou-se da vontade de Teodoro de ampliar e aprofundar o tema de suas investigações de pós-doutorado, por meio da criação de
                                uma equipe de pesquisadores orientados por uma linha de pesquisa dedicada à história das formas de fazer crer e das crenças cristãs
                                em terras ibéricas tardo-medievais. Já estabelecido, o grupo de pesquisa dedicou-se a investigar as formas pelas quais as crenças
                                cristãs foram fabricadas e naturalizadas, principalmente nos reinos de Castela e Portugal, com base na análise de palavras que
                                estruturavam a política pastoral e no exame das produções livrescas direcionadas ao ensino catequético em solo ibérico, entre os
                                séculos XIII e XVI. Assim, reuniram-se documentos de diversos tipos, como as atas de concílios, os opúsculos pastorais, os manuais
                                de confessores, os sermões, os espelhos de príncipes, as recolhas de <em>exempla</em>, entre outros.
                            </p>
                            <p className="paragraph-content">
                                Inicialmente, o grupo de pesquisa teve como sede o Instituto de Filosofia e Ciências Humanas da Universidade
                                Estadual de Campinas – IFCH/UNICAMP. Com a aprovação de Teodoro, em agosto de 2022, no concurso público para a vaga de docente de
                                História Antiga e Medieval, na Faculdade de Ciências e Letras (FCLAs) da Universidade Estadual Paulista (UNESP),
                                Câmpus de Assis, o núcleo foi transferido para o novo local, onde se consolidou. Antes disso, em 2021, o Jovem Pesquisador ganhou desdobramentos
                                com a concessão de mais um auxílio decorrente da chamada bilateral entre a FAPESP e o Fundo Nacional Suíço (FNS).
                                Trata-se do projeto “Instrução e conversão no mundo dos <em>exempla</em> ibéricos: pilares da moral cristã”, sob a responsabilidade de Teodoro e do
                                filólogo Hugo Oscar Bizzarri, professor de Filologia Hispânica e História da Língua no Departamento de Espanhol da
                                Universidade de Friburgo (Suíça), além de editor e autor de inúmeras obras sobre a literatura medieval espanhola.
                                “O convênio com a Universidade de Friburgo ajudou a potencializar acordos que resultaram em eventos e publicações coletivas.
                                Também ajudou a desenvolver novos trabalhos em torno das narrativas medievais”, avalia Teodoro.
                            </p>
                        </div>

                    </div>


                    <div className="text-contentDivulgacao">
                        <p className="paragraph-content">
                            Durante a sua vigência, de outubro de 2018 a setembrode 2024,o projeto Jovem Pesquisador
                            contou com uma equipe formada por docentes associados – Hugo Oscar Bizzarri (UniFR-Suíça), Rui Luis Rodrigues (IFCH-UNICAMP),
                            Ana Paula Tavares Magalhães Tacconi (FFLCH-USP) e Carolina Coelho Fortes (IHT-UFF); pesquisadores associados
                            (mestrandos, mestres, doutorandos, doutores e pós-doutores); 15 bolsistas de treinamento técnico de diversos níveis
                            (graduados, mestres e doutores); 2 bolsistas de Jornalismo Científico; e 15 bolsistas de Iniciação Científica. “Foi uma experiência profícua,
                            uma vez que me possibilitou a interlocução com pesquisadores renomados, o contato com fontes primárias e a análise crítica dos processos
                            de ensino e assimilação da fé cristã no contexto da sociedade ibérica tardo-medieval, aspectos que, a meu ver, contribuíram para a construção de
                            uma base teórico-metodológica sólida essencial às minhas investigações futuras”, diz o historiador Thiago Augusto Motta sobre a sua experiência como
                            bolsista de Iniciação Científica do grupo. Alguns pesquisadores de IC do projeto foram contemplados com a Bolsa Estágio de Pesquisa no
                            Exterior (BEPE), da FAPESP. Foi o caso de Ana Clara Toledo, atualmente, aluna do 3º ano de História da UNESP-Câmpus de Assis, que, durante dois
                            meses, pôde desenvolver a sua pesquisa em Portugal. “Tive o prazer de ser orientada pela profa. Dra. Maria João Branco e de frequentar a
                            biblioteca da Universidade Nova de Lisboa, local de onde retirei uma vasta bibliografia, que eu não encontraria facilmente no Brasil”.
                        </p>
                    </div>
                    <div className="book-image" style={{ flex: '1', textAlign: 'center' }}>
                        <img src={img2} alt="Capa do Livro" style={{ width: '700px', height: 'auto', marginBottom: '10px' }} />
                        <p style={{ marginTop: '10px' }}>
                            Leandro Teodoro e o historiador francês Roger Chartier durante o VI Evento Internacional “Instrução e persuasão dos <em>exempla</em>” (UNESP-Câmpus de Assis).
                        </p>
                    </div>


                    <div className="text-contentDivulgacao">

                        <div className="section-title">
                            <h2>PRODUTOS: BANCO DE DADOS, PUBLICAÇÕES E EVENTOS</h2>
                        </div>
                        <div className="text-contentDivulgacao">
                            <p className='paragraph-normal'>
                                Ao longo dos seis anos de atuação, o grupo “O Ensino da fé cristã na península Ibérica (sécs. XIV, XV e XVI)” publicou trabalhos
                                coletivos e individuais, além de organizar inúmeros eventos internacionais e atividades acadêmicas. O principal produto construído,
                                conjuntamente, foi o banco de dados <em>online</em> “Obras pastorais e doutrinárias do mundo ibérico”, disponível no <em>site</em> do projeto
                                (https://umahistoriadapeninsula.com). A plataforma virtual reúne 61 verbetes, produzidos por 34 autores de diversas universidades
                                brasileiras e estrangeiras, a respeito de obras de cunho catequético e doutrinário compostas ou traduzidas na península Ibérica, entre os séculos XIII e XVI.
                            </p>


                            
                            <div className="contentDivulgacao" >
                                <div className="book-image" style={{ flex: '1', textAlign: 'center' }}>
                                    <img src={img3} alt="Capa do Livro" style={{ width: '400px', height: 'auto', marginBottom: '10px' }} />
                                    <p style={{ marginTop: '10px' }}>
                                        Livro “Cativar as almas: diretrizes para a instrução espiritual (Sécs. XII-XV)”.    </p>
                                </div>


                                <div className="text-contentDivulgacao">
                                    <p className="paragraph-content">

                                        Além do banco de dados, foram publicadas obras coletivas, como os livros “Diretrizes para a instrução espiritual – Séc. XII-XV” (Unisinos, 2022),
                                        escrito e organizado por Teodoro e pelos historiadores Jean-Claude Schmitt (Escola de Altos Estudos em Ciências Sociais – França) e
                                        Pablo Martín Prieto (Universidad Complutense de Madrid – Espanha); “A formação de Reinos Virtuosos – Século XII ao XVII” (Editora da UFSC, 2024),
                                        uma coletânea de 17 capítulos, fruto de um evento de mesmo nome, organizada por Teodoro e Ana Paula Tavares Magalhães Tacconi (USP);
                                        <em> Exemplum y discurso ejemplar en la Península Ibérica </em> (Cultura Acadêmica/UNESP, 2024), uma coletânea de 11 capítulos, dirigida por
                                        Teodoro e Hugo Oscar Bizzarri, disponível, gratuitamente, para <em>download</em> no <em>site</em> da editora.
                                    </p>

                                    
                                </div>
                                </div>
                                </div>
                            </div>

                            <div className="contentDivulgacao" >
                            <div className="text-contentDivulgacao">
                            <p className="paragraph-content">
                                        Em relação às publicações individuais, produzidas pelo pesquisador responsável do projeto, Leandro Alves Teodoro, 
                                        destacam-se - além dos artigos, verbetes e capítulos de livros - os seus trabalhos de tradução e edição de documentos, como os
                                        “Guias dos costumes cristãos castelhanos: o Espelho da alma e outros opúsculos pastorais dos séculos XIII, XIV e XV”
                                        (Editora da Unicamp, 2024), uma antologia de seis opúsculos traduzidos, pela primeira vez, para a língua portuguesa e
                                        “Diretrizes para a formação cristã. Os primeiros escritos sobre os comportamentos em língua portuguesa (sécs. XIV-XVI)”
                                        (prelo), uma edição semidiplomática de 22 opúsculos portugueses. Há também estudos a serem lançados nos próximos anos,
                                        como: “Santos Guardadores: a formação de costumes na Península Ibérica”, uma coletânea de diversos textos escritos por Teodoro,
                                        ao longo do projeto; <em>Guidelines for spiritual formation from the 13th to 15th century </em> (“Diretrizes para a formação espiritual do
                                        século XIII ao século XV”), um dossiê, em língua inglesa, da revista <em>Mediaeval Studies</em> (Brepols), organizado por Teodoro, contendo
                                        textos do historiador, de Jean-Claude Schmitt (EHESS-França) e de Pablo Martín Prieto (UCM-Espanha);
                                        e uma edição das fábulas de Esopo, preparada por Teodoro em parceria com Hugo Oscar Bizzarri.
                                    </p>
                                    <p className="paragraph-content">

                                    Quanto aos eventos internacionais, foram organizados um total de seis: o I Seminário Internacional do Grupo 
                                    (2019), realizado no IFCH/UNICAMP; o II Evento Internacional “A Formação de Reinos Virtuosos na Idade Média” 
                                    (2020), ocorrido virtualmente; o III Evento Internacional “Aconselhar e prescrever em terras cristãs” (2021), 
                                    realizado virtualmente; o IV Evento Internacional “Ética e formação moral do <em>exemplum"</em> (2022), realizado no 
                                    IFCH/UNICAMP; o V Evento Internacional “Práticas de devoção no mundo ibérico medieval” (2023), realizado na 
                                    FCLAs da UNESP – Câmpus de Assis e, por fim, o VI Evento Internacional “Instrução e persuasão dos <em>exempla</em>” 
                                    (2024), ocorrido no mesmo local.

</p>
</div>


                                </div>


                                <div className="book-image" style={{ flex: '1', textAlign: 'center' }}>
                        <img src={img1} alt="Capa do Livro" style={{ width: '800px', height: 'auto', marginBottom: '10px' }} />
                        <p style={{ marginTop: '10px' }}>
                        Leandro Teodoro e a equipe de monitores do VI Evento Internacional “Instrução e persuasão dos <em>exempla”</em> (UNESP-Câmpus de Assis).                        </p>
                    </div>

                    <div className="contentDivulgacao" >
                            <div className="text-contentDivulgacao">
                            <p className='paragraph-normal'>
                            Além dos congressos internacionais, o projeto promoveu inúmeras atividades, como cursos de introdução à paleografia ibérica com 
                            especialistas nacionais e internacionais, palestras e conferências – ministradas por Teodoro e por outros pesquisadores, no Brasil e no
                             exterior – oficinas, cursos de extensão, minicursos, intercâmbios, estágios de pesquisa e disciplinas 
                             ofertadas na pós-graduação.
</p>
                            <p className="paragraph-content">
                            “O Jovem Pesquisador atingiu seu objetivo de analisar o fazer crer em razão de dois pontos: primeiro, o número de produtos 
                            coletivos e individuais apresentados, entre banco de dados, um <em>site</em>, edição de documentos e eventos internacionais. 
                            Além disso, conseguiu formar um núcleo de pesquisa com a 
                            participação ativa de um conjunto de interlocutores nacionais e internacionais”, faz um balanço Teodoro.

                                </p>





                                </div>
                                </div>

                            <div className="content-columns-artigoII">
                                <div className="column-artigoII">
                                    <h3 id='h3-artigoII'>Projeto</h3>
                                    <p>
                                        » O ensino da fé cristã na península Ibérica, sécs. XIV, XV e XVI (FAPESP n. 17/11111-9). 
                                        Modalidade: Jovem Pesquisador. Pesquisador responsável: Leandro Alves Teodoro (Unesp-Assis)
                                    </p>
                                     
                                    <p>
                                        » Instrução e conversão no mundo dos exempla ibéricos: pilares da moral cristã (Processo FAPESP n. 21/02936-0). Modalidade: Convênio. Pesquisador
                                        responsável: Leandro Alves Teodoro (Unesp-Assis). Pesquisador responsável no exterior: Hugo Oscar Bizzarri 
                                        (Universidade de Friburgo – Suíça).

                                    </p>

                                     
 

  
                                    

                                </div>



                            </div>


                            <p>  <strong>  Patrícia Serieiro.</strong> Bolsista de Jornalismo Científico-IV/ FAPESP (Processo 2024/01457-9).</p>

                            <div className="pdf-tbn-evento">
                                <a href="https://bucket.server.umahistoriadapeninsula.com/artigos/Ensinar_a_fe_crista_na_peninsula_Iberica_medieval.pdf" target="_blank" class="btn btn-main mt-20">Baixar Arquivo</a>
                            </div>


                            <footer className="footerDivulgacao">
                                <p>O ENSINO DA FÉ CRISTÃ NA PENÍNSULA IBÉRICA (SÉCS. XIV, XV E XVI)</p>
                                <p> Novembro de 2024</p>
                            </footer>

                        </main>

                    </div>
                    <Footer />
            </div>
            );
    }


            export default Ensinar_a_fe_crista_na_peninsula_Iberica_medieval;