import React, { useState } from 'react';
import './EventoSelecionados.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';
import ImagemVIEvento from '../../assets/img/PostVIEventoInstrucaoepersuasaodosexempla.jpg'; 
import './VIEvento.css'; 
import './VideoSection.css';

function Event({ time, title }) {
    return (
        <div className="event">
            <div className="time">{time}</div>
            <div className="details">
                <div className="title">
                    <span className="event-title-line">{title}</span>
                </div>
            </div>
        </div>
    );
}

export default function VIevento() {
    const [activeTab, setActiveTab] = useState('programacao');
    const [subTab, setSubTab] = useState('04/06');  
    
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div>
            <div className="TituloEventosSelecionados">
                <div className="row">
                <div className="row">
    <div className="col-md-12">
        <h1>VI Evento Internacional</h1>
        <h2>INSTRUÇÃO E A PERSUASÃO DOS EXEMPLA</h2>
        <h2>O ensino da fé cristã na Península Ibérica</h2>
    </div>
    </div></div>
            </div>

            <div className="VEventoMov">
                <div className="container" id="TituloEventosSelecionados">
                    <div className="inscricoesBox">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'programacao' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('programacao')}
                                >
                                    Programação
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'programacaoDetalhada' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('programacaoDetalhada')}
                                >
                                    Programação Detalhada
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'cadernoResumos' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('cadernoResumos')}
                                >
                                    Caderno de Resumos
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'inscricoes' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('inscricoes')}
                                >
                                    Inscrições
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'videos' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('videos')}
                                >
                                    Vídeos do Evento
                                </a>
                            </li>
                           
                        </ul>
                        {activeTab === 'programacao' && (
    <div className="programacao-evento">
        <h3>Programação do Evento</h3>
        <p>Aqui está a programação geral do evento:</p>
        <ul>
            <li>Dia 4 de Junho:</li>
            <ul>
                <li>08:00h – 09:30h:
                    <ul>
                        <li>Minicurso 1 - Narrativas populares nos tratados demonológicos</li>
                        <li>Minicurso 2 - Introdução à leitura da crônica na Espanha e na América (Século XVI)</li>
                    </ul>
                </li>
                <li>10:00h – 12:00h: Minicurso 3 - A história do vestuário e suas fontes escritas</li>
                <li>12:00h – 14:00h: Almoço</li>
                <li>14:00h – 15:30h:
                    <ul>
                        <li>Minicurso 1 - Narrativas populares nos tratados demonológicos</li>
                        <li>Minicurso 2 - Introdução à leitura da crônica na Espanha e na América (Século XVI)</li>
                    </ul>
                </li>
                <li>16:00h – 18:00h: Minicurso 3 - A história do vestuário e suas fontes escritas</li>
                <li>18:00h – 19:30h: Credenciamento</li>
                <li>19:30h – 20:00h: Abertura</li>
                <li>20:00h: Palestra - Vidas exemplares dominicanas no Portugal medieval</li>
            </ul>
            <br></br>
            <li>Dia 5 de Junho:</li>
            <ul>
                <li>10:00h – 12:00h: Mesa redonda 1 - A persuasão dos <i>exempla</i> ibéricos</li>
                <li>12:00h – 14:00h: Almoço</li>
                <li>14:00h – 15:30h: Simpósio 1 - Saberes e práticas de instrução e persuasão no período moderno (séculos XV-XVIII)</li>
                <li>16:00h – 18:00h: Simpósio 2 - O papel da persuasão na condução de corpos e almas na Idade Média</li>
                <li>20:00h: Palestra - <i>Exempla e loci communes</i> na Renascença</li>
            </ul>
            <br></br>
            <li>Dia 6 de Junho:</li>
            <ul>
                <li>10:00h – 12:00h: Mesa redonda 2 - A exemplificação de virtudes e pecados</li>
                <li>12:00h – 14:00h: Almoço</li>
                <li>14:00h – 15:30h: Simpósio 1 - Saberes e práticas de instrução e persuasão no período moderno (séculos XV-XVIII)</li>
                <li>16:00h – 18:00h: Simpósio 2 - O papel da persuasão na condução de corpos e almas na Idade Média</li>
                <li>20:00h: Mesa redonda 3 - A exemplificação entre o teatro e o sermão</li>
            </ul>
        </ul>
        <div className="row">
            <img className="img-fluid" src={ImagemVIEvento} alt="Nova Imagem do Evento" />
        </div>
    </div>
)}




{activeTab === 'programacaoDetalhada' && (
     <div>
    
     {activeTab === 'programacaoDetalhada' && (
          <div className="programacao-detalhada">
             <h3>Programação Detalhada do Evento</h3>
             
             
             <ul className="nav nav-tabs">
                 <li className="nav-item">
                     <button
                         className={`nav-link ${subTab === '04/06' ? 'active' : ''}`}
                         onClick={() => setSubTab('04/06')}
                     >
                         DIA 04/06 – TERÇA-FEIRA
                     </button>
                 </li>
                 <li className="nav-item">
                     <button
                         className={`nav-link ${subTab === '05/06' ? 'active' : ''}`}
                         onClick={() => setSubTab('05/06')}
                     >
                         DIA 05/06 – QUARTA-FEIRA
                     </button>
                 </li>
                 <li className="nav-item">
                     <button
                         className={`nav-link ${subTab === '06/06' ? 'active' : ''}`}
                         onClick={() => setSubTab('06/06')}
                     >
                         DIA 06/06 – QUINTA-FEIRA
                     </button>
                 </li>
             </ul>

          
             {subTab === '04/06' && (
    <div className="programacao-detalhada">
        <h3>Programação Detalhada do Evento - 04 de Junho</h3>

        <ul>
            <li className='liHorarios'>8:00h – 9:30h</li>
            <ul>
                <li className='espacamento-li'>
                    Minicurso 1
                    <ul>
                        <li>Narrativas populares nos tratados demonológicos</li>
                         <span className="sub-info">Prof.ª Dr.ª Lívia G. Toquetti - Unicamp</span>
                    </ul>
                </li>
                <br></br>
                <li className='espacamento-li'>
                    Minicurso 2
                    <ul>
                        <li>Introdução à leitura da crônica na Espanha e na América (Século XVI)</li>
                         <span className="sub-info">Prof.ª Dr.ª Maria Emília Granduque José - Unesp</span> 
                    </ul>
                </li>
            </ul>

            <li className='liHorarios'>9:30h - 10:00h</li>
            <ul>
                <li>INTERVALO</li>
            </ul>
            <li className='liHorarios'>10:00h - 12:00h</li>
            <ul>
                <li className='espacamento-li'>
                    Minicurso 3
                    <ul>
                        <li>A história do vestuário e suas fontes escritas</li>
                        <span className="sub-info">Prof. Dr. Thiago Henrique Alvarado - Unesp</span> 
                    </ul>
                </li>
            </ul>
            <li className='liHorarios'>12:00h - 14:00h</li>
            <ul>
                <li>ALMOÇO</li>
            </ul>
            <li className='liHorarios'>14:00h - 15:30h</li>
            <ul>
                <li className='espacamento-li'>
                    Minicurso 1
                    <ul>
                        <li>Narrativas populares nos tratados demonológicos</li>
                         <span className="sub-info">Prof.ª Dr.ª Lívia G. Toquetti - Unicamp</span>
                    </ul>
                </li>
                <br></br>
                <li className='espacamento-li'>
                    Minicurso 2
                    <ul>
                        <li>Introdução à leitura da crônica na Espanha e na América (Século XVI)</li>
                         <span className="sub-info">Prof.ª Dr.ª Maria Emília Granduque José - Unesp</span> 
                    </ul>
                </li>
            </ul>
            <li className='liHorarios'>15:30h – 16:00h</li>
            <ul>
                <li>INTERVALO</li>
            </ul>
            <li className='liHorarios'>16:00h - 18:00h</li>
            <ul>
                <li className='espacamento-li'>
                    Minicurso 3
                    <ul>
                        <li>A história do vestuário e suas fontes escritas</li>
                         <span className="sub-info">Prof. Dr. Thiago Henrique Alvarado - Unesp</span> 
                    </ul>
                </li>
            </ul>
            <li className='liHorarios'>18:00h - 19:30h</li>
            <ul>
                <li>Credenciamento</li>
            </ul>
            <li className='liHorarios'>19:30h - 20:00h</li>
            <ul>
                <li>Abertura</li>
            </ul>
            <li className='liHorarios'>20:00h</li>
            <ul>
                <li>Palestra - Vidas exemplares dominicanas no Portugal medieval</li>
                 <span className="sub-info">Prof. Dr. Saul António Gomes – Universidade de Coimbra</span> 
            </ul>
        </ul>
    </div>
)}


{subTab === '05/06' && (
    <div className="programacao-detalhada">
        <h3>Programação Detalhada do Evento - 05 de Junho</h3>
        <ul>
            <li className='liHorarios'>10:00h - 12:00h</li>
            <ul>
                <li className='espacamento-li'>
                    Mesa redonda 1: A persuasão dos <i>exempla</i> ibéricos
                    <ul>
                        <li>De la Biblia al <i>exemplum</i>: modos de persuación</li>
                         <span className="sub-info">Prof. Dr. Hugo O. Bizzarri (Université de Fribourg)</span> 
                      </ul><ul>
                        <br></br>
                        <li>Realeza e instrução parental: vias de transmissão e valores priorizados</li>
                         <span className="sub-info">Prof.ª Dr.ª Isabel de Barros Dias (Universidade Aberta de Portugal)</span> 
                    
                    </ul>
                </li>
            </ul>
            <li className='liHorarios'>12:00h - 14:00h</li>
            <ul>
                <li>ALMOÇO</li>
            </ul>
            <li className='liHorarios'>14:00h - 15:30h</li>
            <ul>
                <li className='espacamento-li'>Simpósio 1</li>
                <ul>
                    <li>Saberes e práticas de instrução e persuasão no período moderno (séculos XV-XVIII)</li>
                    <span className="sub-info">Prof.ª Dr.ª Paula Esposito (Unesp) e Doutorando Franco Alves Biondi (Unicamp)</span>
                </ul>
            </ul>
            <li className='liHorarios'>15:30h - 16:00h</li>
            <ul>
                <li>INTERVALO</li>
            </ul>
            <li className='liHorarios'>16:00h - 18:00h</li>
            <ul>
                <li className='espacamento-li'>Simpósio 2</li>
                <ul>
                    <li>O papel da persuasão na condução de corpos e almas na Idade Média</li>
                    <span className="sub-info">Doutorando André Silva Ranhel (Unesp) e Prof.ª Dr.ª Patrícia Antunes Serieiro Silva (Unesp)</span>
                </ul>
            </ul>
            <li className='liHorarios'>20:00h</li>
            <ul>
                <li>Palestra <i>Exempla e loci communes</i> na Renascença</li>
                <ul>
                    <span className="sub-info">Prof. Dr. Roger Chartier (Collège de France)</span> 
                </ul>
            </ul>
        </ul>
    </div>
)}


{subTab === '06/06' && (
    <div className="programacao-detalhada">
        <h3>Programação Detalhada do Evento - 06 de Junho</h3>
        <ul>
            <li className='liHorarios'>10:00h - 12:00h</li>
            <ul>
                <li className='espacamento-li'>
                    Mesa redonda 2: A exemplificação de virtudes e pecados
                    <ul>
                        <li>
                            Francisco eremita: a construção da memória no <i>Speculum perfectionis</i> e no <i>Speculum perfectionis minus</i>
                            <br />
                            <span className="sub-info">Prof.ª Dr.ª Ana Paula Tavares (Universidade de São Paulo)</span>
                        </li></ul><ul>
                        <li>
                            Una sección sobre la mentira en el <i>Libro de buen amor</i>
                            <br />
                            <span className="sub-info">Prof.ª Dr.ª María Luzdivina Cuesta Torre (Universidad de Léon)</span>
                        </li>
                    </ul>
                </li>
            </ul>
            <li className='liHorarios'>12:00h - 14:00h</li>
            <ul>
                <li>ALMOÇO</li>
            </ul>
            <li className='liHorarios'>14:00h - 15:30h</li>
            <ul>
                <li className='espacamento-li'>
                    Simpósio 1
                    <ul>
                        <li>Saberes e práticas de instrução e persuasão no período moderno (séculos XV-XVIII)</li>
                        <span className="sub-info">Prof.ª Dr.ª Paula Esposito (Unesp) e Doutorando Franco Alves Biondi (Unicamp)</span>
                    </ul>
                </li>
            </ul>
            <li className='liHorarios'>15:30h - 16:00h</li>
            <ul>
                <li>INTERVALO</li>
            </ul>
            <li className='liHorarios'>16:00h - 18:00h</li>
            <ul>
                <li className='espacamento-li'>
                    Simpósio 2
                    <ul>
                        <li>O papel da persuasão na condução de corpos e almas na Idade Média</li>
                        <span className="sub-info">Doutorando André Silva Ranhel (Unesp) e Prof.ª Dr.ª Patrícia Antunes Serieiro Silva (Unesp)</span>
                    </ul>
                </li>
            </ul>
            <li className='liHorarios'>20:00h</li>
            <ul>
                <li>
                    Mesa redonda 3: A exemplificação entre o teatro e o sermão
                    <ul>
                        <li>La persuasión ejemplar en el teatro del <i>Siglo de Oro</i></li>
                        <span className="sub-info">Prof. Dr. Abraham Madroñal (Université de Gènevre)</span>  
                        </ul> <br></br>
                        <ul>
                        <li>Evoluções e tendências no uso de exempla nos sermões de auto da fé portugueses. Sécs. XVII e XVIII</li>
                        <span className="sub-info">Prof. Dr. Bruno Feitler (Unifesp)</span>
                    </ul>
                </li>
            </ul>
        </ul>
    </div>
)}



          </div>
     )}
 </div>
 
     
)}

                        {activeTab === 'cadernoResumos' && (
                            <div>

<div className='formularioDiv'>

    <div className="caderno-resumos">
        <h1 style={{ color: 'blue' }}>Disponível</h1>
    </div>

                            <div className="pdf-tbn-evento">
                                {/* <a href="http://www.inscricoes.fmb.unesp.br/index.asp?configurar=true&codEvento=14270" target="_blank" class="btn btn-main mt-20">Inscreva Se aqui</a>*/}
                              
                                
                                <a href="https://bucket.server.umahistoriadapeninsula.com/eventos/VIEventocadernoderesumos.pdf" target="_blank" class="btn btn-main mt-20">Baixar Caderno de Resumos</a>
                              
                                  
                           
                            </div>
</div>
 

                                 <div className="row">
                            <img className="img-fluid" src={ImagemVIEvento} alt="Nova Imagem do Evento" />
                        </div>
                            </div>
                        )}

                        {activeTab === 'inscricoes' && (
                            <div>

            <div className="pdf-tbn-evento">
                <h1 style={{ color: 'blue' }}>Inscrições Encerradas</h1>
                </div>

                <div className="inscricoes">
        <p>O evento “Instrução e a persuasão dos exempla” reunirá especialistas do campo da filologia e da história para debater sobre o papel moralizante dos contos, fábulas e outros escritos que circularam entre a Idade Média e a Época Moderna. Serão oferecidos três minicursos e Simpósios Temáticos abertos aos interessados em apresentar trabalhos sobre o tema ou assuntos afins. Trata-se de um evento financiado pela Fundação de Amparo à Pesquisa do Estado de São Paulo e pelo Fundo Nacional Suíço.</p>
    </div>
        <div className="pdf-tbn-evento">
             <a href="https://www.inscricoes.fmb.unesp.br/index.asp?configurar=true&codEvento=15136" target="_blank" class="btn btn-main mt-20">INSCREVA-SE  </a>
       

       
    </div>

                                <div className="row">
                            <img className="img-fluid" src={ImagemVIEvento} alt="Nova Imagem do Evento" />
                        </div>
                            </div>
                        )}

{activeTab === 'videos' && (
                            <div className="video-section">
                            <h2>Videos - VI Evento Internacional</h2>
                            <div className="video-grid">
 
                              <div className="video-item">
                                 <iframe src="https://www.youtube.com/embed/OutcmaS6sso" title="Conferência 04.06.24 (Noite) - VI Evento Internacional - A instrução e a persuasão dos exempla"></iframe>
                              </div>
                              <div className="video-item">
                                <iframe src="https://www.youtube.com/embed/Wgw8dIxH4l0" title="Video 2"></iframe>
                              </div>
                              <div className="video-item">
                                <iframe src="https://www.youtube.com/embed/70BJ6xX3tgw" title="Video 3"></iframe>
                              </div>
                              <div className="video-item">
                                <iframe src="https://www.youtube.com/embed/OaDE5Jblc80" title="Video 4"></iframe>
                              </div>
                              <div className="video-item">
                                <iframe src="https://www.youtube.com/embed/S83V6rGv3AI" title="Video 5"></iframe>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                </div>
            </div>

             
            <Footer />
        </div>
    );
}
