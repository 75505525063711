import React from 'react';
import './DivulgacaoCientificaComponent.css';
import './Cards/CardItemArtigos.css';
import CardsItemArtigos from './Cards/CardItemArtigos';

const DivulgacaoCientificaComponent = () => {
  return (
    <div className='cards_artigo'>
        
      <div className='cards__container_artigo'>
        <div className='cards__wrapper_artigo'>
          <ul className='cards__items_artigo'>
            <CardsItemArtigos  
              src='https://bucket.server.umahistoriadapeninsula.com/artigos/capa-do-livro.jpeg'
              name='Resenha'
              text=' '
              label='Visitar'
              path='/ArtigoI'
            />
 
       <CardsItemArtigos  
              src='https://bucket.server.umahistoriadapeninsula.com/artigos/capa-do-artigoII.png'
              name='Virtudes e Pecados'
              text=' '
              label='Visitar'
              path='/ArtigoII'
            />

<CardsItemArtigos  
              src='https://bucket.server.umahistoriadapeninsula.com/artigos/capaDoLivro_artigoIII.png'
              name='Resenha'
              text=' '
              label='Visitar'
              path='/ArtigoIII'
            />
  
          </ul>
          <ul className='cards__items_artigo'> 
          <CardsItemArtigos  
              src='https://bucket.server.umahistoriadapeninsula.com/artigos/As_fabulas_de_Esopo.png'
              name='As fábulas de Esopo'
              text=' '
              label='Visitar'
              path='/As_fabulas_de_Esopo'
            />  

         
          <CardsItemArtigos  
              src='https://bucket.server.umahistoriadapeninsula.com/artigos/Ensinar_a_fe_crista_na_peninsula_Iberica_medieval5.png'
              name='Ensinar a fé cristã na península Ibérica medieval'
              text=' '
              label='Visitar'
              path='/Ensinar_a_fe_crista_na_peninsula_Iberica_medieval'
            />  
          </ul> 
        </div>
      </div>
    </div>
  );
}

export default DivulgacaoCientificaComponent;
