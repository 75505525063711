import React from 'react';
import './Convenios.css'
import LogoSnsf from '../assets/img/Logosnsf.png'
import Footer from '../components/Footer';
import { IoIosDoneAll } from 'react-icons/io';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Convenios() {
    return (
        <div>
            <div class="convenios">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Convênios</h1>

                    </div>
                </div>
            </div>

            <section class="convenio-section" id="">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <img class="img-fluid" src={LogoSnsf} />
                        </div>

                        <div class="col-md-6">
                            <h2 class="h2class">Instrução e conversão no mundo dos exempla ibéricos: pilares da moral cristã</h2>
                            <p class="text-muted text-justify" id="fontTextConvenios">O presente projeto vem propor a criação de um núcleo de pesquisa internacional e
                                interdisciplinar com dupla sede, a Universidade Estadual de Campinas (UNICAMP) e a
                                Université de
                                Fribourg (Suíça), que se dedicará aos estudos das funções edificantes das recolhas de
                                exempla
                                produzidas ou compiladas em línguas vernáculas na Península Ibérica do século XIII ao XVI.
                                Envolvendo pesquisadores de universidades brasileiras e europeias - como a Université de
                                Genève
                                (Suíça), Universidade de São Paulo (USP), Universidade Federal de São Paulo (UNIFESP),
                                Universidad de León (Espanha) e a Universidade Estadual Paulista (UNESP) - a proposta
                                consistirá
                                em examinar jogos de aprendizagem da moral cristã em que as historietas moralizantes se
                                tornaram
                                peças-chave para facilitar a memorização de tipos de condutas recomendadas ou recriminadas
                                mediante à exemplificação de virtudes e pecados. Esta pesquisa abordará o processo de
                                naturalização de crenças cristãs em solo ibérico a partir da análise do papel atribuído ao
                                exemplum no ensino de comportamentos promovidos nas cortes régias e nas pregações populares,
                                de
                                modo que será considerado o seu público-alvo e meio de circulação. Como norte da análise
                                desse
                                roteiro de condutas, o núcleo examinará em que medida os exempla reforçaram uma disciplina
                                que
                                visava ensinar cuidados a serem tomados consigo, com o próprio corpo e a alma, bem como com
                                o
                                seu grupo social. Nesse sentido, os seus pesquisadores examinarão narrativas que procuravam
                                estabelecer critérios para ação em dois âmbitos da vida, na relação consigo e com os outros.
                            </p>


                        </div>

                    </div>

        <Container>
            <Row>
                <Col><h6><IoIosDoneAll /> Processo: 21/02936-0 </h6></Col>
                <Col><h6> <IoIosDoneAll />Linha de fomento: Auxílio à Pesquisa - Regular </h6></Col>
            </Row>
        </Container>
        <Container>
            <Row> 
            <Col><h6><IoIosDoneAll /> Vigência: 01 de outubro de 2021 - 30 de setembro de 2025</h6></Col>
            <Col><h6><IoIosDoneAll /> Área do conhecimento: Ciências Humanas - História</h6></Col>

            </Row>
        </Container>

        <Container>
            <Row> 
            <Col><h6><IoIosDoneAll /> Pesquisador responsável: Leandro Alves Teodoro </h6></Col>
            <Col><h6><IoIosDoneAll /> Beneficiário: Leandro Alves Teodoro </h6></Col>

            </Row>
        </Container>

        <Container>
            <Row> 
            <Col><h6><IoIosDoneAll /> Pesq. responsável no exterior: Hugo Bizzarri </h6></Col>
            <Col><h6><IoIosDoneAll /> Instituição no exterior: Université de Fribourg, Suíça </h6></Col>

            </Row>
        </Container>

        <Container>
            <Row> 
            <Col><h6><IoIosDoneAll /> Instituição-sede: Instituto de Filosofia e Ciências Humanas (IFCH). Universidade Estadual de Campinas (UNICAMP). Campinas , SP, Brasil </h6></Col>
            <Col><h6><IoIosDoneAll /> Pesq. associados: Abraham Madroñal Durán ; Ana Paula Tavares Magalhães Tacconi ; Bruno Guilherme Feitler ; María Luzdivina Cuesta Torre </h6></Col>

            </Row>
        </Container>

        <Container>
            <Row> 
            <Col><h6><IoIosDoneAll /> Assunto(s): Península Ibérica Língua vernacular Manuscritos Moral cristã Narrativas moralizantes </h6></Col>
            <Col> </Col>

            </Row>

            
        </Container>
                   
         
                </div>


                <div>
                            <div className="pdf-tbn-evento">
                            <a href="https://bv.fapesp.br/pt/auxilios/108932/instrucao-e-conversao-no-mundo-dos-exempla-ibericos-pilares-da-moral-crista/" target="_blank" class="btn btn-evento-main mt-10">Ver Publicação</a>

                            </div>

                        </div>


                 
            </section>

           

                       
            <Footer />
        </div>
    )
}
