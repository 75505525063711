import React from 'react';
 import './Home.css';
import { Noticias } from  '../components/UltimasNoticias'
 
import { FaleConosco } from "./FaleConosco";
  
 import Footer from '../components/Footer';

  
 import DadosSite from '../components/DadosSite'

 import Sliderb from '../components/Slider/animatedSlider'
import { Container } from '@material-ui/core';

 function Home() {

  return (
    <div>
       
     
      
        
          
           <div>  <Sliderb />  </div> 
             
            <DadosSite />
          <Noticias /> 

           
         <FaleConosco />
         <Footer />
        
         
            
         
         
      
 

</div>

      )
}

      export default Home;
