import React from 'react';
import './EventoSelecionados.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';

   

export default function IVEventos() {
    return (
         <div>  
       
            <div class="TituloEventosSelecionados">
                <div class="row">
                    <div class="col-md-12">
                        <h1>IV Evento Internacional
Ética e formação moral do exemplum</h1>
                     </div>

                     
                </div>
            </div>
            <div class="container">
            <div class="row">
                
                <div class="col-12">
                    <div class=" title-white text-justify ">
                        
                        
                       
                </div>
            
            
            
            
                <div class="table-responsive">
                    <table class="table table-bordered ">
                        <thead>
                        <tr class="bg-dark text-white">
                            <th colspan="4" class="text-center">Programação</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="bg-light">
                            <th scope="row" colspan="3" class="text-center bg-success">09.08.2022</th>
                        </tr>
                        <tr>
                            <th scope="row" colspan="1" class="text-center"></th>
                            <th scope="row" colspan="1" class="text-center"></th>
                            <th scope="row" colspan="1" class="text-center"></th>
                        </tr>
                        <tr>
                            <th scope="row" class="text-center">08h30</th>
                            <td colspan="2" class="text-center">
                                Credenciamento
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" class="text-center">09h00</th>
                            <th colspan="2" class="text-center">
                                Abertura
                            </th>


                        </tr>
                        <tr>
                            <th scope="col" class="text-center">09h15 às 09:45 </th>
                            <td>
                                <strong> Conferência: </strong> O exemplum na ficção quinhentista portuguesa

                            </td>
                            <td colspan="1">
                                Profa. Dra. Lênia Márcia Mongelli (FFLCH-USP)
                                
                                <small> <strong>Mediação:</strong> Prof. Dr. Leandro Alves Teodoro
                                    (IFCH-UNICAMP/Unesp-Assis)</small>
                            </td>

                        </tr>
                        <tr>
                            <th scope="col" class="text-center">10h00</th>
                            <td colspan="2" class="text-center">
                                <strong>Coffee-break</strong>
                            </td>

                        </tr>

                        <tr>
                            <th scope="col" class="text-center">10:20
                            </th>
                            <td colspan="1">
                                <strong>Mesa 1 </strong>


                            </td>
                            <td colspan="1">

                                <strong> Juan Ruiz moralista</strong>
                               
                                Prof. Dr. Hugo Oscar Bizzarri (Université de Fribourg/Suíça)
                             
                                <strong> A correção dos sentidos corpóreos nos exempla ibéricos</strong>
                               
                                Prof. Dr. Leandro Alves Teodoro (IFCH-UNICAMP/UNESP-Assis)

                            
                                <strong> O exemplo como evidência no Auto da Cananéia (1534) de Gil Vicente</strong>
                               
                                Prof. Dr. Alexandre Soares Carneiro (IEL-UNICAMP)
                              

                                <small>
                                    <strong> Mediação:</strong> Profa. Dra. Ana Paula T. M. Tacconi (FFLCH-USP)
                                </small>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" class="text-center">12h00</th>
                            <td colspan="2" class="text-center">
                                <strong>Almoço</strong>
                            </td>

                        </tr>

                        <tr>
                            <th scope="col" class="text-center">14h00 as 17h00


                            </th>
                            <td colspan="1">
                                <strong> Simpósio Temático:</strong>
                                “Narrativas e exemplos na Idade Média e na Modernidade”.


                            </td>
                            <td colspan="1">

                                <strong>Organizadores:</strong>
                                Caroline G. Mendes (UNICAMP)
                             
                                Franco A. Biondi (UNICAMP)
                              
                                Lívia G. Torquetti (UNICAMP)
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" class="text-center">17h00</th>
                            <td colspan="2" class="text-center">
                                <strong>Coffee-break</strong>
                            </td>

                        </tr>

                        <tr>
                            <th scope="row" colspan="4" class="text-center bg-danger text-white">10.08.2022</th>
                        </tr>
                        <tr>
                            <th scope="row" colspan="1" class="text-center"></th>
                            <th scope="row" colspan="1" class="text-center"></th>
                            <th scope="row" colspan="1" class="text-center"></th>
                        </tr>
                        <tr>
                            <th scope="row" class="text-center">09h00 às 09h30m</th>
                            <td colspan="1"><strong>Conferência:</strong> Los exempla de Ramon Llull: formación moral y
                                espiritual
                            </td>
                            <td colspan="1">
                                Profa. Dra. Dominique de Courcelles
                                (CNRS -UMR 8230-Centre Jean Pépin-Théta/ École polytechnique-Ehess/ Université
                                Paris-Dauphine - França)
                              

                                <small> <strong> Mediação:</strong> Profa. Dra. Isabel Maria de Barros Dias
                                    (DH-Universidade Aberta – Portugal)</small>
                            </td>
                        </tr>

                        <tr>
                            <th scope="col" class="text-center">09h50</th>
                            <td colspan="2" class="text-center">
                                <strong>Coffee-break</strong>
                            </td>

                        </tr>

                        <tr>
                            <th scope="col" class="text-center">10h10</th>
                            <td colspan="1" class="">
                                <strong>Mesa 2</strong>
                            </td>
                            <td colspan="1" class="">
                                <strong> O exemplum em crónicas ibéricas</strong>
                               
                                Profa. Dra. Isabel Maria de Barros Dias (DH-Universidade Aberta – Portugal)
                           
                                <strong> O sonho como aspecto da edificação moral: a experiência onírica nos primeiros
                                    escritos franciscanos (século XIII)</strong>
                               
                                Profa. Dra. Ana Paula T. M. Tacconi (FFLCH-USP)

                            
                                <strong> O exemplum na obra de Clemente Sánchez de Vercial</strong>
                               
                                Profa. Dra. María Luzdivina Cuesta Torre (Universidad de León – Espanha)
                             

                                <small><strong> Mediação:</strong> Prof. Dr. Alexandre Soares Carneiro
                                    (IEL-UNICAMP)</small>

                            </td>

                        </tr>
                        <tr>
                            <th scope="col" class="text-center">11h30</th>
                            <td colspan="2" class="text-center">
                                <strong>Almoço</strong>
                            </td>

                        </tr>

                        <tr>
                            <th scope="col" class="text-center">14h00 as 17h00


                            </th>
                            <td colspan="1">
                                <strong> Simpósio Temático:</strong>
                                “Narrativas e exemplos na Idade Média e na Modernidade”.
                            </td>
                            <td colspan="1">

                                <strong>Organizadores:</strong>
                               
                                Caroline G. Mendes (UNICAMP)
                            
                                Franco A. Biondi (UNICAMP)
                            
                                Lívia G. Torquetti (UNICAMP)
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" class="text-center">17h00</th>
                            <td colspan="2" class="text-center">
                                <strong>Coffee-break</strong>
                            </td>

                        </tr>

                        <tr>
                            <th scope="row" colspan="4" class="text-center bg-info">11.08.2022</th>
                        </tr>
                        <tr>
                            <th scope="row" colspan="1" class="text-center"></th>
                            <th scope="row" colspan="1" class="text-center"></th>
                            <th scope="row" colspan="1" class="text-center"></th>
                        </tr>
                        <tr>
                            <th scope="col" class="text-center">09h00 às 09h30
                                            </th>
                            <td colspan="1">
                                <strong>Conferência:</strong> O manuscrito de Urbania


                            </td>
                            <td colspan="1">

                                Prof. Dr. Alcir Pécora (IEL-UNICAMP)
                             
                                <small>
                                    <strong> Mediação:</strong> Prof. Dr. Luiz Estevam de Oliveira Fernandes
                                    (IFCH-UNICAMP)
                                </small>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" class="text-center">09h50</th>
                            <td colspan="2" class="text-center">
                                <strong>Coffee-break</strong>
                            </td>

                        </tr>


                        <tr>
                            <th scope="col" class="text-center">10h10</th>
                            <td colspan="1" class="text-center">
                                <strong>Mesa 3</strong>
                            </td>
                            <td colspan="1" class="">

                                <strong> A moralidade e a imoralidade do ouro nas Américas, século XVI</strong>
                               
                                Prof. Dr. Luiz Estevam de Oliveira Fernandes (IFCH-UNICAMP)
                               
                                <strong> Os exemplos históricos nos "Discursos morais, políticos e históricos" de
                                    Antonio de Herrera
                                </strong>
                               
                                Profa. Dra. Maria Emília Granduque José (UNESP-Franca)


                                
                                <strong> Exortações e formação moral na Nova Espanha do século XVI
                                </strong>
                               
                                Prof. Dr. Anderson Roberti dos Reis (UFMT)
                              

                                <small><strong> Mediação:</strong> Prof. Dr. Bruno  Feitler
                                    (EFLCH-UNIFESP)</small>

                            </td>

                        </tr>
                        <tr>
                            <th scope="col" class="text-center">11h30</th>
                            <td colspan="2" class="text-center">
                                <strong>Almoço</strong>
                            </td>

                        </tr>
                        <tr>
                            <th scope="col" class="text-center">14h00 -  15h (Encerramento)
                            </th>
                            <td colspan="1" class="text-center">
                                <strong>Mesa 4</strong>
                            </td>
                            <td colspan="1" class="">

                                <strong> O uso de exempla nos textos de polêmica antijudaica portugueses (sécs. XVI-XVIII)
                                </strong>
                              
                                Prof. Dr. Bruno  Feitler (EFLCH-UNIFESP)

                               
                                <strong> os usos da história sacra [bíblica] na obra de Pierre Viret de 1563
                                </strong>
                               
                                Prof. Dr. Rui Luis Rodrigues (IFCH-UNICAMP)
                        

                                <small><strong> Mediação:</strong>Prof. Dr. Anderson Roberti dos Reis (UFMT)
                                </small>

                            </td>

                        </tr>
                        <tr>
                            <th scope="col" class="text-center">15h00 às 17h00


                            </th>
                            <td colspan="1">
                                <strong> Simpósio Temático:</strong>
                                “Narrativas e exemplos na Idade Média e na Modernidade”.
                            </td>
                            <td colspan="1">

                                <strong>Organizadores:</strong>
                                
                                Caroline G. Mendes (UNICAMP)
                               
                                Franco A. Biondi (UNICAMP)
                             
                                Lívia G. Torquetti (UNICAMP)
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            
            
                <div>
                            <div className="pdf-tbn-evento">
                                <a href="https://bucket.server.umahistoriadapeninsula.com/eventos/IVevento_caderno-de-resumo-evento-2022-04-08-22pdf.pdf" target="_blank" class="btn btn-main mt-20">Baixar Caderno de Resumos</a>
                              
 
                            </div>

                        </div>
            
          

                <div class="symposia">
                    <div class="title-wrap text-center">
                        <h2 class="text-center m-2">Simpósio</h2>
                        <h3 class="text-center m-2">Narrativas e exemplos na Idade Média e na Modernidade</h3>
                    </div>

                   
                    <div>
                        <p>
                            O simpósio Narrativas e exemplos na <i>Idade Média</i> e na <i>Modernidade</i>,
                            realizado no
                            âmbito do <i>IV Evento Internacional do Grupo “O Ensino da Fé Cristã na Península
                                Ibérica” – Ética e Formação Moral no Exemplum</i>, acolhe pesquisadores de
                            diferentes níveis de formação que pesquisam características singulares dos
                            comportamentos das sociedades cristãs do período medieval ou do moderno. Abrindo
                            espaço para discorrer sobre diversas produções escritas, tanto manuscritas
                            quanto impressas, que nos ajudem a explicar as dinâmicas e modos de organização
                            dessas sociedades, o objetivo será reunir trabalhos que examinem a normatização
                            de costumes, as emoções, bem como projetos teológico-político e a correção dos
                            corpos.
                        </p>
                        <p>
                            Nesse encontro em que o alvo consistirá em esquadrinhar formas de pactuação da
                            norma, também serão contemplados estudos sobre a institucionalização do cristianismo
                            dentro dos limites do Império Romano e as sucessivas iniciativas de reforma do corpo
                            eclesial e secular; a produção de manuais, tratados e textos prescritivos com o
                            intuito de promover modelos e ideais; a veiculação da informação por cartas,
                            manuscritos e, posteriormente, pela prensa; a desnaturalização e reprovação de
                            modelos discrepantes da ortodoxia e do padrão moral, a exemplo daqueles considerados
                            heréticos, demoníacos e feiticeiros, como também os “outros”, “pagãos” e “infiéis”,
                            externos ao contexto da cristandade; e os diálogos intelectuais, orientados em torno
                            da erudição e das discussões sobre a melhor forma de conduzir os assuntos públicos.

                        </p>
                        <p>
                            Assim, visando condensar essas temáticas em torno da ideia de “moral”, propõem-se
                            seis eixos para o debate: 1) a produção e circulação de manuais, tratados e textos
                            prescritivos, manuscritos e impressos; 2) as narrativas sobre heresias, bruxarias e
                            demonologia; 3) produção e circulação de manuais devocionais, livros de conselhos e
                            exemplos; 4) governança,<i> Razão de Estado e Espelhos de Príncipes</i>; 5) as
                            narrativas
                            de viagem e 6) moral e gênero nos contextos medieval e moderno.
                        </p>

                    </div>

                </div>

            </div>  
        </div>
 
  
         
             
        </div>      


            
<Footer />  
        </div>
         
    )
}
