import React from 'react';
import './EventoSelecionados.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';


export default function IIEvento() {
    return (
        <div>
            <div class="TituloEventosSelecionados">
                <div class="row">
                    <div class="col-md-12">
                        <h1>II Evento Internacional  </h1>
                        <h1>A FORMAÇÃO DE REINOS VIRTUOSOS NA IDADE MÉDIA</h1>
                        <h2>O ensino da fé cristã na Península Ibérica.</h2>
                    </div>
                </div>
            </div>

            <div className='EventosMov'>
                <div class="container" id='TituloEventosSelecionados'>
                   
                    <div class="row">
                        <div>
                            <div className="titulo-evento">
                                <h1>07,08 e 09 de Outubro de 2020</h1>
                                <div class="border"></div>
                            </div>
                        </div>

                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">MESA 1: INSTITUIÇÕES E RELAÇÕES DE PODER</h2>
                           <h6>A fala de abertura será do Prof. Dr. Leandro Alves Teodoro (Unicamp/Unesp).
Já a primeira mesa será "Instituições e Relações de Poder", e contará com a presença do Prof. Dr. Bernardo Vasconcelos e Sousa (IEM-UNL), que apresentará "A construção do Portugal Medievo: Poder, Território, Reino", e da Profa. Dra. Maria Filomena Coelho (UNB), com o tema "O Estado Virtuoso: corpos e pluralismo político em Portugal (séc. XII-XIII)".
A mediação será do Prof. Dr. Leandro Alves Teodoro.</h6>
                            <div class="href-item">
                                 <a href="https://youtu.be/5XMnhPLxIC0"></a>
                            </div>
                             <iframe width="100%" height="225" src="https://www.youtube.com/embed/5XMnhPLxIC0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                       
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">MESA 2: A AÇÃO MORALIZADORA DE CULTOS E ORAÇÕES</h2>
                            <h6>A segunda mesa será "A Ação Moralizadora de Cultos e Orações", e contará com a presença da Profa. Dra. Maria Amélia Álvaro de Campos (CHSC/UC), Profa. Dra. Douglas Mota Xavier de Lima (UFOPA), e Dra. Letícia Gonçalves Alfeu de Almeida (FAPESP/Unicamp).
A mediação será da Dra. Simone Ferreira Gomes de Almeida (FAPESP/Unicamp) </h6>
                            <div class="href-item">
                                <a href="https://www.youtube.com/watch?v=kT_RPf_TDKE"></a>
                            </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/kT_RPf_TDKE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                        
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">MESA 3: A FORMAÇÃO DE MODELOS DE CONDUTA NO FINAL DA IDADE MÉDIA</h2>
                            <h6>A terceira mesa será "A formação de modelos de conduta no final da Idade Média", e contará com a presença da Profa. Dra. Ana Paula Tavares Magalhães (USP) e da Profa. Dra. Susani Silveira Lemos França (UNESP/Franca).
A mediação será da Profa. Dra. Carolina Coelho Fortes (UFF).</h6>
                            <div class="href-item">
                                <a href="https://www.youtube.com/watch?v=gwYhUdE3OA0"></a>
                            </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/gwYhUdE3OA0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                        
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">MESA 4: A FÉ E SEUS INTERMEDIÁRIOS</h2>
                            <h6>A quarta mesa será "A fé e seus intermediários", e contará com a presença do Prof. Dr. João Luís Fontes (IEM/UNL), e da Profa. Dra. Carolina Coelho Fortes (UFF).
A mediação será da Profa. Dra. Ana Paula Tavares Magalhães (USP). </h6>
                            <div class="href-item">

                            </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/eS1siOumZZc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                       
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">MESA 5: MANIFESTAÇÕES DA FÉ E A CONSTRUÇÃO DE REINOS VIRTUOSOS</h2>
                            <h6>A quinta mesa terá como tema "Manifestações da fé e a construção de Reinos Virtuosos", e contará com a presença do Prof. Dr. Gabriel Castanho (UFRJ) e da Profa. Dra. Renata Cristina de Sousa Nascimento (UFG/ PUC Goiás).
A mediação será do Dr. Thiago Magela (UFF)</h6>
                            <div class="href-item">
                             </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/OXx44Jd19zU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                        
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">MESA 6: A ESCRITA E SEUS MEIOS DE CIRCULAÇÃO</h2>
                            <h6>A sexta mesa terá como tema "A Escrita e seus meios de circulação", e contará com a presença da Profa. Dra. Ana Isabel Buescu (CHAM/UNL) e da Profa. Dra. Patrícia Dalcanale Meneses (Unicamp).</h6>
                            <div class="href-item">
                             </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/iFVfC-xmY6I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                       
                        <div class="col-6" id='link-item'>
                            <h2 class="text-justify">MESA 7: PARÂMETROS DE VIRTUDES E AÇÕES CONDENÁVEIS</h2>
                            <h6>A sétima mesa terá como tema "Parâmetros de virtudes e ações condenáveis", e contará com a presença da Profa. Dra. Rita Costa Gomes (Towson University),
Profa. Dra. Ana Paula Torres Megiani (USP) e Prof. Dr. Rui Luís Rodrigues (Unicamp).
A mediação será do Prof. Dr. Aldair Carlos Rodrigues (Unicamp).</h6>
                            <div class="href-item">
                             </div>
                            <iframe width="100%" height="225" src="https://www.youtube.com/embed/KnsF2CCNTbE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>

                        <div>
                            <div className="pdf-tbn-evento">
                                <a href="https://bucket.server.umahistoriadapeninsula.com/eventos/IIevento-caderno-de-resumos-07.10.pdf" target="_blank" class="btn btn-main mt-20">Baixar Caderno de Resumos</a>
                              
                                <a href="https://bucket.server.umahistoriadapeninsula.com/eventos/IIevento-caderno-de-resumos-07.10.pdf" target="_blank" class="btn btn-main mt-20">Programação</a>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )

}
