import React from 'react';
import './Css/DadosSite.css'
import navIcon1 from '../assets/img/icon-documento.png'
import navIcon2 from '../assets/img/icon-livro-aberto.png'
import navIcon3 from '../assets/img/icon-people.png'
import navIcon4 from '../assets/img/icon-pesquisar.png'

export default function DadosSite() {
    return (
      <div>
 
 <section class="counter-wrapper section-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="">
 
                        <h1 className="dados-title">Uma história da Península</h1>
                        <p className="dados-subtitle"> O grupo Ensino da fé cristã na Península Ibérica possui como propósito analisar o processo
                            de naturalização de crenças cristãs nos mundos ibéricos da Idade Média e começo dos Tempos
                            Modernos.</p>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6 col-xs-6 text-center ">
                    <div class="counters-item">
                     <img src={navIcon1} alt="" />
                        <div>
                            <span className="counter" data-count="58">58</span>
                        </div>
                        <h3 className='dados-h3'>Documentos</h3>  
                    </div>
                </div>

                <div class="col-md-3 col-sm-6 col-xs-6 text-center ">
                    <div class="counters-item">
                      <img src={navIcon2} alt="" />   
 
                        <div>
                             <span class="counter" data-count="11128">11528</span>
                        </div>
                        <h3 className='dados-h3'>Páginas indexadas</h3>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-xs-6 text-center ">
                    <div class="counters-item">
                    <img src={navIcon3} alt="" />   

                        <div>
                            <span class="counter" data-count="84342">104342</span>
                        </div>
                        <h3 className='dados-h3'>Visitas no Site</h3>

                    </div>
                </div>

                <div class="col-md-3 col-sm-6 col-xs-6 text-center ">
                    <div class="counters-item kill-border">
                    <img src={navIcon4} alt="" />   
                        <div>
                            <span class="counter" data-count="5719">13719</span>
                        </div>
                        <h3 className='dados-h3'>Consultas de documentos</h3>
                    </div>
                </div>
 
            </div>
        </div>
    </section>


    </div>
    )
}
   