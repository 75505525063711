import React from 'react';
import { Link } from 'react-router-dom';
import './CardItemArtigos.css';

function CardsItemArtigos(props) {
    return (
        <li className='cards__item_artigo'>
            <Link className='cards__item__link_artigo' to={props.path}>
                <div className='cards__item__link_artigo'>
                    <figure className='cards__item__pic-wrap_artigo' data-category={props.label}>
                        <img
                            className='cards__item__img_artigo'
                            alt='Artigo'
                            src={props.src}
                        />
                    </figure>
                    <div className='cards__item__info_artigo'>
                        <h5 className='cards__item__name'>{props.name}</h5>
                        <h5 className='cards__item__text'>{props.text}</h5>
                    </div>
                </div>
            </Link>
        </li>
    );
}

export default CardsItemArtigos;
