 import React from 'react';
import './Css/Cards.css';
import CardItem from './CardItem';

function CardsTeste() {

  return (
    <div className='cards'>
      <h1 className='title-page'>Pesquisadores</h1>
      <h2 className='subtitle-page'>Membros da Equipe</h2>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>


            <CardItem
              src='images/Membros/Leandro.jpg'
              name='Leandro Alves Teodoro'
              subtitle='Coordenador'
              text='Professor colaborador do departamento de História do Instituto de Filosofia e Ciências Humanas da Universidade Estadual de Campinas (UNICAMP).'
              label=''
              path="mailto:leandroateodoro@uol.com.br"
              pathb='http://lattes.cnpq.br/8706720482638081'
            />

            <CardItem
              src='images/Membros/rui.jpeg'
              name='Rui Luís Rodrigues'
              subtitle='Professor associado'
              text='Doutor em História Social pela Universidade de São Paulo.'
              label=''
              path="mailto:umahistoriadapeninsula@gmail.com"
              pathb='http://lattes.cnpq.br/0512724090143385'
            />

            <CardItem
              src='images/Membros/AnaPaula.jpeg'
              name='Ana Paula Tavares Magalhães'
              subtitle='Professora associada'
              text='Doutorado em História Social pela Universidade de São Paulo.'
              label=''
              path="mailto:umahistoriadapeninsula@gmail.com"
              pathb='http://lattes.cnpq.br/3890004296147407'
            />
 </ul>
             
          

          <ul className='cards__items'>

          <CardItem
              src='images/Membros/CarolinaCoelho.jpeg'
              name='Carolina Coelho Fortes'
              subtitle='Professora associada'
              text='Doutorado em História pela Universidade Federal Fluminense .'
              label=''
              path="mailto:carolfortes@hotmail.com"
              pathb=''
            />

            <CardItem
              src='images/Membros/HugoB.jpeg'
              name='Hugo O. Bizzarri'
              subtitle='Professor Associado'
              text='Professor titular de Filologia Hispânica e de História das Línguas na Université de Fribourg.'
              label=''
              path="mailto:hugo.bizzarri@unifr.ch"
              pathb=''
            />

            <CardItem
              src='images/Membros/MariaEmilia.jpeg'
              name='Maria Emília Granduque José'
              subtitle='Pesquisadora Associada'
              text='Doutora em História Cultural pela Universidade Estadual de Campinas (UNICAMP).'
              label=''
              path="mailto:memiliagranduque@gmail.com"
              pathb='http://lattes.cnpq.br/7951231540522286'
            />

<CardItem
              src='images/Membros/Patricia.jpeg'
              name='Patrícia Antunes Serieiro Silva'
              subtitle='Apoio Técnico e Pesquisadora associada'
              text='Doutora em História Social pela Universidade de São Paulo (USP).'
              label=''
              path="mailto:pantunes@usp.br"
              pathb='http://lattes.cnpq.br/7149065470206236'
            />

 

 </ul>
           

          <ul className='cards__items'>

          <CardItem
              src='images/Membros/AnaCarolina.jpeg'
              name='Ana Carolina Machado de Souza'
              subtitle='Apoio Técnico e Pesquisadora associada'
              text='Doutoranda em História Cultural pela Universidade Estadual de Campinas.'
              label=''
              path="mailto:ana.c.m.s@hotmail.com"
              pathb='http://lattes.cnpq.br/3464932342249401'
            />

            <CardItem
              src='images/Membros/Simone.jpeg'
              name='Simone Ferreira Gomes de Almeida'
              subtitle='Apoio Técnico (bolsista) e Pesquisadora associada'
              text='Doutora em História Social pela UNESP – Franca.'
              label=''
              path="mailto:simonefgalmeida@hotmail.com"
              pathb='http://lattes.cnpq.br/5883832606957231'
            />
          

            <CardItem
              src='images/Membros/Michele.jpeg'
              name='Michelle Souza e Silva'
              subtitle='Apoio Técnico (bolsista) e Pesquisadora associada'
              text='Doutora em História, ambos pela UNESP – Franca.'
              label=''
              path="mailto:contato.sahis@gmail.com"
              pathb='http://lattes.cnpq.br/5792973791286085'
            />

<CardItem
              src='images/Membros/pedro.jpeg'
              name='Pedro Kanagusto'
              subtitle='Apoio Técnico(bolsista) e Programador '
              text='Graduado em Ciência da computação pela Universidade de Franca'
              label=''
              path="mailto:pedrokanagusto@gmail.com"
              pathb=''
            />
</ul>

<ul className='cards__items'>
            <CardItem
              src='images/Membros/Leticiagoncalves.jpeg'
              name='Letícia Gonçalves Alfeu de Almeida'
              subtitle='Apoio Técnico e Pesquisadora associada'
              text='Doutora em História pela UNESP – Franca.'
              label=''
              path="mailto:leticiaalfeudealmeida@gmail.com"
              pathb='http://lattes.cnpq.br/0560151253508595'
            />
            <CardItem
              src='images/Membros/Yasmin.jpeg'
              name='Yasmin de Andrade Leandro'
              subtitle='Pesquisadora (Mestranda)'
              text='Mestranda em História Medieval pela UNESP – Franca.'
              label=''
              path='mailto:yasmin-leandro@hotmail.com'
              pathb='http://lattes.cnpq.br/7570544709007166'

            />
 <CardItem
              src='images/Membros/Gesner.jpeg'
              name='Gesner Las Casas Brito Filho'
              subtitle='Apoio Técnico e Pesquisador associado'
              text='Doutor em História Medieval pela University of Leeds, Reino Unido (2019), com bolsa CAPES.'
              label=''
              path="mailto:gesnerlascasas@gmail.com"
              pathb='http://lattes.cnpq.br/6883595843756636'
            />


<CardItem
              src='images/Membros/Monique.jpeg'
              name='Monique Marques Nogueira Lima'
              subtitle='Apoio Técnico e Pesquisadora associada'
              text='Doutora em História e Cultura Social pela Universidade Estadual Paulista (UNESP - Franca), Doutorado sanduíche na Universidade de Lisboa.'
              label=''
              path="mailto:moniquemarqueslima@outlook.com"
              pathb='http://lattes.cnpq.br/0091097339926318'
            />
          </ul>


<ul className='cards__items'>

            

            <CardItem
              src='images/Membros/Paloma.jpeg'
              name='Paloma Schmitz'
              subtitle='Aluna Associada'
              text='Graduanda em História no Instituto de Filosofia e Ciências Humanas (IFCH) da Universidade Estadual de Campinas (UNICAMP).'
              label=''
              path="mailto:p223046@dac.unicamp.br"
              pathb='http://lattes.cnpq.br/9995412587894088'
            />
            <CardItem
              src='images/Membros/Livia.jpeg'
              name='Lívia Alves Dos Santos'
              subtitle='Aluna Associada'
              text='Graduanda em História no Instituto de Filosofia e Ciências Humanas (IFCH) da Universidade Estadual de Campinas (UNICAMP).'
              label=''
              path="mailto:l244716@dac.unicamp.br"
              pathb='http://lattes.cnpq.br/4738091676262766'
            />

<CardItem
              src='images/Membros/Marina.jpeg'
              name='Marina Luz de Carvalho'
              subtitle='Aluna Associada'
              text='Graduanda em História no Instituto de Filosofia e Ciências Humanas (IFCH) da Universidade Estadual de Campinas (UNICAMP).'
              label=''
              path="mailto:m184766@dac.unicamp.br"
              pathb='http://lattes.cnpq.br/4975178975050092'
            />
             
             <CardItem
              src='images/Membros/andre.jpeg'
              name='André Silva Ranhel'
              subtitle='Aluno associado'
              text='Mestrando pelo Programa de Pós-Graduação em História da UNESP.'
              label=''
              path="mailto:andreranhel@gmail.com"
              pathb='https://www.escavador.com/sobre/4496228/andre-silva-ranhel'
            />
          </ul>

          <ul className='cards__items'>





            
            

            <CardItem
              src='images/Membros/Diovani.jpeg'
              name='Diovani Matheus Marques'
              subtitle='Aluno associado'
              text='Graduando em História no Instituto de Filosofia e Ciências Humanas (IFCH) da Universidade Estadual de Campinas (UNICAMP).'
              label=''
              path="mailto:diovanimatheus7@gmail.com"
              pathb='http://lattes.cnpq.br/9087711753141613'
            />

            <CardItem
              src='images/Membros/Nicolas.jpeg'
              name='Nicollas Douglas De Souza Pereira'
              subtitle='Aluno associado'
              text='Graduando em História pela Universidade Estadual de Campinas.'
              label=''
              path="mailto:n242563@dac.unicamp.br"
              pathb=''
            />

<CardItem
              src='images/Membros/LeticiaDiniz.jpeg'
              name='Leticia Diniz'
              subtitle='Aluna associada'
              text='Graduanda em História no Instituto de Filosofia e Ciências Humana (IFCH) da Universidade Estadual de Campinas (UNICAMP).'
              label=''
              path="mailto:leticiaddiniz22@gmail.com"
              pathb=''
            />

<CardItem
              src='images/Membros/Guilherme.jpeg'
              name='Guilherme Barbosa dos Santos'
              subtitle='Aluno associado'
              text='Graduando em História pela Universidade Estadual de Campinas (UNICAMP).'
              label=''
              path="mailto:guibarbosa294@gmail.com"
              pathb='http://lattes.cnpq.br/9357116576652272'
            />
          </ul>
          <ul className='cards__items'>




            
        

            <CardItem
              src='images/Membros/Giovanna.jpeg'
              name='Giovanna Mauro'
              subtitle='Aluna associada'
              text='Graduanda em História pela Universidade Estadual de Campinas (UNICAMP).'
              label=''
              path="mailto:giovannamauro@yahoo.com"
              pathb=''
            />

            <CardItem
              src='images/Membros/Thiago.jpeg'
              name='Thiago Henrique Alvarado'
              subtitle='Aluno associado'
              text='Mestre em História pela UNESP - Franca.'
              label=''
              path="mailto:thiagoalvarado@gmail.com"
              pathb='http://lattes.cnpq.br/5126326079556616'
            />
             <CardItem
              src='images/Membros/Flavia.jpeg'
              name='Flávia Catusso Daisson Santos'
              subtitle='Aluna associada'
              text='Graduanda em História pela Universidade Estadual de Campinas.'
              label=''
              path="mailto:flavia.catusso@gmail.com"
              pathb='http://lattes.cnpq.br/9300515444830835'
            />

<CardItem
              src='images/Membros/rodrigo.jpeg'
              name='Rodrigo Narciso da Silva'
              subtitle='Aluno associado'
              text='Graduando em História na Universidade Estadual de Campinas (UNICAMP).'
              label=''
              path="mailto:rodrigo-n.s@hotmail.com"
              pathb='http://lattes.cnpq.br/4041247859090355'
            />
          </ul>



          
          <ul className='cards__items'>


            
 

            <CardItem
              src='images/Membros/FotoFilipi.jpg'
              name='Filipi Gomes de Souza'
              subtitle='Aluno associado'
              text='Graduando em História na Faculdade de Ciências e Letras da Universidade Estadual Paulista "Júlio de Mesquita Filho" (UNESP - Câmpus de Assis).'
              label=''
              path="mailto:filipi.gomes@unesp.br"
              pathb='http://lattes.cnpq.br/8502344063526329'
            />
            <CardItem
              src='images/Membros/ThiagoComelli.jpeg'
              name='Thiago Pereira Camargo Comelli'
              subtitle='Aluno associado'
              text='Graduando em História na Faculdade de Ciências e Letras da Universidade Estadual Paulista "Júlio de Mesquita Filho" (UNESP - Câmpus de Assis), Bacharel em Direito pela Fundação Educacional do Município de Assis (FEMA).'
              label=''
              path="mailto:t.comelli@unesp.br"
              pathb='http://lattes.cnpq.br/1520524219095788'
            />

<CardItem
              src='images/Membros/AnaClara.jpg'
              name='Ana Clara Toledo Borges'
              subtitle='Aluna associada'
              text='Graduando em História na Faculdade de Ciências e Letras da Universidade Estadual Paulista "Júlio de Mesquita Filho" (UNESP - Câmpus de Assis).'
              label=''
              path="mailto:act.borges@unesp.br"
              pathb=''
            />

<CardItem
              src='images/Membros/ThiagoAugusto.jpeg'
              name='Thiago Augusto Motta'
              subtitle='Aluno associado'
              text='Graduando em História na Faculdade de Ciências e Letras (FCL) da Universidade Estadual Paulista "Júlio de Mesquita Filho" (UNESP).'
              label=''
              path="mailto:thiago.motta@unesp.br"
              pathb=''
            />
          </ul>

          <ul className='cards__items'>


           
            

            <CardItem
              src='images/Membros/YuriOmaeCamalhonte.jpeg'
              name='Yuri Omae Camalhonte'
              subtitle='Aluno associado'
              text='Graduando em História na Faculdade de Ciências e Letras da Universidade Estadual Paulista "Júlio de Mesquita Filho" (UNESP - Câmpus de Assis).'
              label=''
              path="mailto:yuri.omae@unesp.br"
              pathb='https://wwws.cnpq.br/cvlattesweb/PKG_MENU.menu?f_cod=F56D36CE48BA22B5EDF95CFE4BBB4136#'

            />

            <CardItem
              src='images/Membros/ClaudiaTrindade.jpeg'
              name='Cláudia Trindade de Oliveira'
              subtitle='Apoio Técnico e Pesquisadora associada'
              text='Formada em História e em Letras pela Universidade Estadual Paulista "Júlio de Mesquita Filho" (UNESP- campus Assis), atua em estudos voltados à religiosidade medieval e com Literatura Comparada.'
              label=''
              path="mailto:triclaudia@gmail.com"
              pathb='http://lattes.cnpq.br/3120482442620598'

            />

<CardItem
              src='images/Membros/EliasFeitosadeAmorimJunior.jpeg'
              name='Elias Feitosa de Amorim Junior'
              subtitle='Aluno associado'
              text='Doutorando em História da Arte Medieval na Université de Paris I (Panthéon-Sorbonne). Mestre em História da Arte-Unicamp. Bacharel e licenciado em História - USP.'
              label=''
              path="mailto:Elias.Feitosa-Amorim-Junior@etu.univ-paris1.fr"
              pathb='http://lattes.cnpq.br/0834644791400480'

            />

<CardItem
              src='images/Membros/MariaIzabel.jpeg'
              name='Maria Izabel Escano Duarte de Souza'
              subtitle='Aluna associado'
              text='Doutora do Programa de Pós-Graduação em História Social da USP com bolsa CAPES Mestra em História pelo Programa de Pós-Graduação em História Social da UFRJ, onde também concluiu a graduação no ano de 2012.'
              label=''
              path="mailto:mariaizabeleds@gmail.com"
              pathb='http://lattes.cnpq.br/9752651643175847'

            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsTeste;
