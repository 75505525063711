import React from 'react';
import '../App.css';
 import Home from './Home';
 
 
function Index() {
  return (
    <>
      <Home />
     
      
    </>
  );
}

export default Index;
