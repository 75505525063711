import React from 'react'
import TextField from '@material-ui/core/TextField'

const SearchBar = props => {
  return (
    <TextField
      label="Faça uma busca em nosso Banco de dados..."
      onInput={props.onInput}
      placeholder="Buscar"
      style={{ width: '100%' }}
      color="warning"
      
    />
  )
}

export default SearchBar
