import React from 'react';
import './Eventos.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import CardsEventos from '../components/CardsEventos'
import Footer from '../components/Footer';


export default function Eventos() {
    return (
        <div>

            <div class="TituloEventos">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Eventos</h1>
                        <h2>O ensino da fé cristã na Península Ibérica.</h2>
                    </div>
                </div>
            </div>

   
           
            <div className='CardsEventos'>

    

                <CardsEventos 
                
                />
 
                <Footer />

            </div>
</div>
     )
 
}
