import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import './DocumentosPdf.css';
import Footer from '../Footer';
import fetchApi from '../../lib/fetch/index.ts';
import { useSecurityContext } from '../../context/SecurityContext.tsx';
import Spinner from "react-bootstrap/Spinner";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentosPdfView = ({ showLink = true }) => {
  const { id, page } = useParams();
  const context = useSecurityContext();
  const location = useLocation();

  const [pdfData, setPdfData] = useState({ title: '', author: '', path: '', fragment: '' });
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const title = new URLSearchParams(location.search).get('title');
  const author = new URLSearchParams(location.search).get('author');

  useEffect(() => {
    const fetchPDF = async () => {
      setLoading(true);

      try {
        const apiCall = await fetchApi.get(`/search/showfragment?id=${id}&page=${page}`, {
          headers: {
            Authorization: context.getToken(),
          },
        });

        if (!apiCall.success) {
          throw new Error(apiCall.message);
        }
        const pdfObject = apiCall.data;
        console.log('PDF Object:', pdfObject);
        setPdfData(pdfObject);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar o PDF:', error);
      }
    };

    fetchPDF();
  }, [id, page]);

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  
  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  
  return (
    <div>
    <div>
      {pdfData && (
        <section className="verbetesIndex-section" id="SectionIndexPdf">
          <div className="VerbetesPdfIndex">
            <div className="row">
              <div className="col-md-12">
                <h1>O ensino da fé cristã na Península Ibérica.</h1>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="text-center">
              <h1 className="section-subheading" id="h1-titulo">
                {title}
              </h1>
              <h3 className="section-heading text-uppercase" id="h3-titulo">
                {author}
              </h3>
            </div>

            <div className="pdf-containerDocumentoPdf">
              {loading ? (
                <div className="divspinnerDocumentoPdf">
                  <Spinner animation="border" variant="warning" /><i>Carregando...</i>
                </div>
              ) : (
                <div className="pdf-container-bDocumentoPdf">
                  {loading ? (
                    <div className="divspinnerDocumentoPdf">
                      <Spinner animation="border" variant="warning" /><i>Carregando...</i>
                    </div>
                  ) : (
                    <div className="pdf-wrapperDocumentoPdf">
                       
                      <Document
                        file={pdfData.fragment}
                        onLoadSuccess={({ numPages }) => {
                          setNumPages(numPages);
                        }}
                      >
                           <div className="pagePdfdocDocumentoPdf">
                          <Page pageNumber={pageNumber} width={820} />
                        </div>
                      </Document>
                      
                    
                       
                      <div className="page-controlsDocumentoPdf">
                        <p className="page-infoDocumentoPdf">
                          Página {pageNumber} de {numPages}
                        </p>
                      </div>

                      <div className='butonsPdfViewDocumentoPdf'>
                        <button onClick={handlePreviousPage} disabled={pageNumber === 1}>
                          Página Anterior
                        </button>
                        <button onClick={handleNextPage} disabled={pageNumber === numPages}>
                          Próxima Página
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </div>
    <Footer />
  </div>
  );
};

export default DocumentosPdfView;
