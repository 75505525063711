import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { apiResponse } from "../types/apiResponse";
const instance: AxiosInstance = axios.create({
  baseURL: "https://api.umahistoriadapeninsula.com/api",
});


 
const get = async (
  url: string,
  config?: AxiosRequestConfig
): Promise<apiResponse> => {
  try {
    const responseHttpRequest: AxiosResponse<apiResponse> = await instance.get(
      url,
      config
    );

    if (responseHttpRequest.status === 200) {
      if (responseHttpRequest.data.redirect) {
        return {
          success: true,
          message: responseHttpRequest.data.message,
          redirect: responseHttpRequest.data.redirect,
        };
      } else {
        if (responseHttpRequest.data.data) {
          return {
            success: responseHttpRequest.data.success,
            data: responseHttpRequest.data.data,
            message: responseHttpRequest.data.message,
          };
        } else {
          return {
            success: responseHttpRequest.data.success,
            message: responseHttpRequest.data.message,
          };
        }
      }
    } else {
      return {
        success: false,
        message: responseHttpRequest.data.message,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};
 
 
const post = async (
    url: string,
    body?: any,
    config?: AxiosRequestConfig
  ): Promise<apiResponse> => {
    try {
      const responseHttpRequest: AxiosResponse<apiResponse> = await instance.post(
        url,
        body,
        config
      );
  
      if (responseHttpRequest.status === 200) {
        if (responseHttpRequest.data.redirect) {
          return {
            success: true,
            message: responseHttpRequest.data.message,
            redirect: responseHttpRequest.data.redirect,
          };
        } else {
          if (responseHttpRequest.data.data) {
            return {
              success: responseHttpRequest.data.success,
              data: responseHttpRequest.data.data,
              message: responseHttpRequest.data.message,
            };
          } else {
            return {
              success: responseHttpRequest.data.success,
              message: responseHttpRequest.data.message,
            };
          }
        }
      } else {
        return {
          success: false,
          message: responseHttpRequest.data.message,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  };
const fetchApi = {
  get,
  post
  
    
};

export default fetchApi;
