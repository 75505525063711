import { Container } from "@material-ui/core";
import React from "react";
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import '../Slider/slider-animations.css';
import '../Slider/styles.css';

import sliderImage from "./sliderImage";


function Sliderb() {
    return (
	 
		 
 
<Slider className="slider-wrapper"  autoplay={5000}>
 
	{ sliderImage.map((item, index) => (
	 
		<div
			key={index}
			className="slider-content"
			style={{ background: `url('${item.image}') no-repeat center center` }}
		>
			 
 					<div className="inner">
						<h1>{item.title}</h1>
						<p>{item.description}</p>
					 <a href={item.path} >	
					 <button>{item.button}</button>
					 </a>
					</div>
				 
 
 				</div>
			 
	 
				 
 			))}
 
 		</Slider>
		
 
 	 
 );
}
 

export default Sliderb;


