import First from '../../assets/img/slider1.jpg';
import Second from "../../assets/img/slider2.jpeg";
import third from '../../assets/img/ACIESP.jpg'
import documentos from '../../assets/img/fundo-SobreBanco.jpg'
import ImagemDivulgacao from '../../assets/img/SliderDiulgaca.jpeg'
export default [

  {
    title: "Divulgação Científica",
    description: "Quer saber mais sobre as pesquisas e as atividades do grupo? Acompanhe as matérias (notícias, reportagens, resenhas e entrevistas) publicadas mensalmente",
    button: 'Visitar',
    image: ImagemDivulgacao,
    path: 'https://umahistoriadapeninsula.com/DivulgacaoCientifica'

  }, 

  {
    title: "Jovens Pesquisadores e a Ciência no Brasil",
    description: "ACIESP Celebra 50 Anos com Evento Dedicado aos Jovens Pesquisadores",
    button: 'Visitar',
    image: third,
    path: 'https://aciesp.org.br/jovens-pesquisadores/'

  },

  {
    title: "Documentos - Obras pastorais e Doutrinárias",
    description: "Convidamos você a explorar a nossa nova página de documentos, onde você encontrará um acervo de documentos históricos agrupados no banco de dados Obras pastorais e doutrinárias do mundo ibérico",
    button: 'Explorar',
    image: documentos,
    path: 'https://umahistoriadapeninsula.com/Documentos'

  }, 

  {
    title: "Instrução e conversão no mundo dos exempla ibéricos: pilares da moral cristã",
    description: "O projeto é um dos quatro selecionados no âmbito da primeira chamada de propostas lançada pela FAPESP em parceria com a Fundação Nacional de Ciência da Suíça (SNSF)",
    button: 'Ver Detalhes',
    image: First,
    path: 'https://umahistoriadapeninsula.com/Convenios'

  }, 
  

   
];
