import React from 'react';
import './FaleConosco.css';
import navIcon1 from '../assets/img/youtube.svg'
import navIcon2 from '../assets/img/twitter.svg'
import navIcon3 from '../assets/img/instagram.svg'
import navIcon4 from '../assets/img/face.svg'
import navIcon5 from '../assets/img/home.png'
import navIcon6 from '../assets/img/endereco.png'
import navIcon7 from '../assets/img/email.png'
import navIcon8 from '../assets/img/phone.png'

import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from '../components/Footer';
  
import 'animate.css';
import TrackVisibility from 'react-on-screen';

 
 

export const FaleConosco = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Enviar');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Enviando...");
    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Enviar");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: 'Mesagem enviada com sucesso'});
    } else {
      setStatus({ succes: false, message: 'Algo deu errado, tente novamente mais tarde.'});
    }
  };

 
   return (
   

    <div>
 
    <section className="contact" id="FaleConosco">
    
    {/* <div class="faleConoscoHeader">
    <div class="row">
        <div class="col-md-12">
            <h1>Uma história da Península</h1>
            <h2>  </h2>
        </div>
    </div>
    </div>  */}
    <Container>
      
 
     
      <Row className="align-items-center">
        <Col size={12} md={6}>
          <TrackVisibility>
          <div class="contact-details ">
                    <h3>O ensino da fé cristã na Península Ibérica</h3>
                    <p>Departamento de História
UNESP/FCL - Câmpus de Assis </p>
                    <ul class="contact-short-info">
                        <li>
                        
                            <span > <img src={navIcon5} id="icons" /> Av. Dom Antonio, 2100 - Parque Universitário</span>
                        </li>
                        <li>
                      
                            <span><img src={navIcon6} id="icons" /> Assis - São Paulo - Brasil - CEP 19806-900</span>
                        </li>
                        <li>
                       
                            <span><img src={navIcon8} id="icons" /> Telefone / VoIP: (18) 3302-5861 / 5861</span>
                        </li>
                        <li>
                     
                            <span> <img src={navIcon7} id="icons" /> E-mail: umahistoriadapeninsula@gmail.com</span>
                        </li>
                    </ul>
                                        <div class="social-icon">
                                        <div className="social-icon">
              <a href="https://www.youtube.com/channel/UCOqszOrTMrhmlUvWCKXiT0A"><img src={navIcon1} alt="" /></a>
              <a href="https://twitter.com/DaPeninsula"><img src={navIcon2} alt="" /></a>
              <a href="https://www.instagram.com/historiadapeninsula_unicamp/"><img src={navIcon3} alt="" /></a>
              <a href="https://www.facebook.com/profile.php?id=61558268759636&locale=pt_BR"><img src={navIcon4} alt="" /></a>
            
            </div>
                    </div>
                 </div>
          </TrackVisibility>
        </Col> 
        <Col size={12} md={6}>
          <TrackVisibility>
            {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
              <h2>Entrar em contato</h2>
              <form onSubmit={handleSubmit} className='form-contato'>
                <Row>
                  <Col size={12} sm={6} className="px-1">
                    <input type="text" value={formDetails.firstName} placeholder="Primeiro Nome" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input type="text" value={formDetails.lasttName} placeholder="Ultimo Nome" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input type="email" value={formDetails.email} placeholder="Endereço de Email" onChange={(e) => onFormUpdate('email', e.target.value)} />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input type="tel" value={formDetails.phone} placeholder="Telefone" onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                  </Col>
                  <Col size={12} className="px-1">
                    <textarea rows="6" value={formDetails.message} placeholder="Menssagem" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                    <button type="submit"><span>{buttonText}</span></button>
                  </Col>
                  {
                    status.message &&
                    <Col>
                      <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                    </Col>
                  }
                </Row>
              </form>
            </div>}
          </TrackVisibility>
        </Col>
      </Row>
    
    </Container>
   
   
   
  </section>
     {/* <Footer /> */}
       </div>
)
}