import React from 'react';
import './Css/UltimasNoticias.css';
 import CardsNoticiasItem from './CardsNoticiasItem';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const Noticias = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="noticias" id="ultimasnoticias">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="noticias-bx wow zoomIn">
              <h2>Últimas notícias</h2>
              <p>Acompanhe as novidades do nosso projeto, novos documentos, verbetes, informações sobre eventos ...</p>

              <Carousel className="custom-carousel" arrows={false}  responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000}>
                <CardsNoticiasItem
                  src='images/capa_ArtigoI.jpeg'
                  text='Livro analisa as funções moralizantes dos exempla (plural de exemplum), refletindo sobre as contribuições que esses relatos breves tiveram na fixação de valores e condutas nos diversos públicos das terras ibéricas, no medievo e no início do período moderno. '
                  label='Leia a resenha"'
                  path='https://umahistoriadapeninsula.com/DivulgacaoCientifica'
                />
                <CardsNoticiasItem
                  src='images/ACIESP.jpg'
                  text='Promovido pela ACIESP em colaboração com a Fundação de Amparo à Pesquisa do Estado de São Paulo (FAPESP) e a Academia Brasileira de Ciências (ABC), este encontro é dedicado aos jovens pesquisadores e tem como objetivo discutir o futuro da ciência no Brasil.'
                  label='Novidade'
                  path='https://aciesp.org.br/jovens-pesquisadores/'
                />
                <CardsNoticiasItem
                  src='images/Noticia1.jpeg'
                  text='Banco de dados reúne textos criados para difundir a moral cristã no fim da Idade Média'
                  label='Novidade'
                  path='https://agencia.fapesp.br/banco-de-dados-reune-textos-criados-para-difundir-a-moral-crista-no-fim-da-idade-media/37930/'
                />
                <CardsNoticiasItem
                  src='images/Noticia2.jpeg'
                  text='Brasileiros e suíços se unem para estudar temas que vão de narrativas medievais a computadores quânticos'
                  label='Novidade'
                  path='https://agencia.fapesp.br/brasileiros-e-suicos-se-unem-para-estudar-temas-que-vao-de-narrativas-medievais-a-computadores-quanticos/37178/'
                />
                <CardsNoticiasItem
                  src='images/Noticia4.jpeg'
                  text='Realizou-se na Universidade de Friburgo o simpósio "Exemplum e discurso exemplar na Península Ibérica (sec. XIII-XVII)". Dirigido pelo Prof. Hugo O. Bizzarri e Leandro Alves Teodoro '
                  label='Colóquio "Exemplum"'
                  path='https://www.unifr.ch/esp/es/news/news/29293/?utm_source=news&utm_medium=&utm_campaign=redirection_from_homehttp%3A%2F%2Fwww.unifr.ch%2Fesp%2Fes%2F'
                />
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      {/* <img className="background-image-left" src={colorSharp} alt="Image" /> */}
    </section>
  );
};
