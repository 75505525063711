import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { Document, Page, pdfjs } from "react-pdf";
import Footer from "../Footer";
import "./VerbetesPDF.css";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const verbetesURL = "https://siteapi.umahistoriadapeninsula.com/api/umahistoria/";

const VerbetesPDF = ({ showLink = true }) => {
   
  const { id } = useParams();
  const [verbete, setVerbete] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingPdf, setLoadingPdf] = useState(true);
  const [loading, setLoading] = useState(false); // Adicione a declaração de loading aqui
  const [bottomLoading, setBottomLoading] = useState(false);

 
  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleSearch = async () => {
    setBottomLoading(true);
    setLoading(true); 
    if (!searchText || !pdfUrl) {
      setSearchResults([]);
      return;
    }

    const results = [];
    const pdfDoc = await pdfjs.getDocument(pdfUrl).promise;

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdfDoc.getPage(pageNum);
      const textContent = await page.getTextContent();

      for (let item of textContent.items) {
        const text = item.str.toLowerCase();
        if (text.includes(searchText.toLowerCase())) {
          results.push({ page: pageNum, text: item });
        }
      }
    }

    setSearchResults(results);
    setLoading(false);
    setBottomLoading(false);
  };

  useEffect(() => {
    handleSearch();
  }, [searchText, pdfUrl]);

  useEffect(() => {
    const verbeteUrl = `${verbetesURL}${id}`;
    async function fetchVerbete() {
      setLoadingPdf(true);  

      try {
        const res = await fetch(verbeteUrl);
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        const data = await res.json();
        setVerbete(data);
        if (data && data.pdfdoclink) {
          const pdfData = await fetch(data.pdfdoclink).then((res) => res.blob());
          const pdfUrl = URL.createObjectURL(pdfData);
          setPdfUrl(pdfUrl);
        }
      } catch (error) {
        console.error("Erro ao buscar verbete:", error);
      }
      finally {
        setLoadingPdf(false); 
      }
    }
    fetchVerbete();
  }, [id]);

  const handlePageChange = () => {
    if (pageNumber >= 1 && pageNumber <= numPages) {
      handleSearch();
    } else {
    
      
    }
  };

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      handleSearch();
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      handleSearch();
    }
  };


   

  return (
    <div>
      <div className="verbetepdfdiv">
        {verbete && (
          <section className="verbetesIndex-section" id="SectionIndexPdf">
            <div className="VerbetesPdfIndex">
              <div className="row">
                <div className="col-md-12">
                  <h1>O ensino da fé cristã na Península Ibérica.</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="text-center">
                <h1 className="section-subheading" id="h1-titulo">
                  {verbete.title}
                </h1>
                <h3 className="section-heading text-uppercase" id="h3-titulo">
                  {verbete.autor}
                </h3>

                {showLink && (
                  <Link
                    className="btn btn-visualizar-verete-main mt-10"
                    to={`/Verbetes/${verbete.id}`}
                  >
                    Visualizar Verbete
                  </Link>
                )}
              </div>
            </div>
          </section>
        )}
      </div>

      <div>
      <div>
        <div className="search-navigation-section">
          <div className="DivTextField">
            <TextField
              multiline
              minRows={4}
              label="Faça uma busca de palavra no Documentos ..."
              placeholder="Buscar"
              style={{
                width: '80%',
                height: '60%'
              }}
              color="warning"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button className="search-button" onClick={handleSearch}>
              {loading ? (
                <Spinner animation="border" variant="warning" size="sm" />
              ) : (
                "Buscar"
              )}
            </button>
          </div>

          {/* Adicionar uma barra de rolagem para a paginação */}

          <div className="page-buttons-container">

      <button onClick={goToPreviousPage}>Pagina anterior</button>
      <button onClick={goToNextPage}>Proxima pagina</button>

       
    </div>

          <div className="page-count">
              <span>Página {pageNumber} de {numPages}</span>
            </div>

          <div className="main-container">
            <div className="pdf-container">
           
              <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} width={600} />
              </Document>
            </div>

            <div className="search-results">
              <h2>Resultados encontrados</h2>
              <table>
                <thead>
                  <tr>
                    <th>Páginas</th>
                    <th>{searchResults.length} Resultados Encontrados</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((result, index) => (
                    <tr key={index}>
                      <td className="page-number">Página {result.page}</td>
                      <td className="search-text">
                        <span style={{ fontStyle: 'normal' }}>Resultado Encontrado:</span> {result.text.str}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {bottomLoading && (
                <div className="bottom-loading">
                  <Spinner animation="border" variant="warning" size="sm" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div> </div>
  );
};

export default VerbetesPDF;
