import React, { useEffect } from 'react';
import './ArtigoI.css';
import img1 from '../../../assets/img/img1_As_fabulas_de_Esopo.png'
import img2 from '../../../assets/img/img2As_fabulas_de_Esopo.png'
import img3 from '../../../assets/img/img3As_fabulas_de_Esopo.png'
import img4 from '../../../assets/img/img4As_fabulas_de_Esopo.jpg'
import img5 from '../../../assets/img/img5As_fabulas_de_Esopo.jpg'



import Footer from '../../../components/Footer';
import { colors } from '@material-ui/core';


     


const As_fabulas_de_Esopo = () => {
  useEffect(() => {
    // Envia o evento de visualização de página para o Google Analytics
    window.gtag('config', 'G-SKHGMNM734', {
      page_path: 'https://www.googletagmanager.com/gtag/js?id=G-SKHGMNM734'
    });
  }, []);

    
  return (
<div>
     <div class="TituloArtigoI">
        <div class="row">
         <div class="col-md-12">
            <h1>Divulgação Científica</h1> 
        </div>
     </div>  
    </div> 

    <div className="containerDivulgacao"> 
    <main className="main-contentDivulgacao">
 
  
    <h1 className="titleDivulgacao">
    As fábulas de Esopo: da exemplaridade medieval ao mundo contemporâneo
        </h1><br></br>
        <p className="subtitleDivulgacao">Projeto leva alunos do Ensino Médio a pesquisarem sobre as narrativas de tradição esópica na Idade Média e nos dias atuais</p>
        <div className="text-contentDivulgacao">
        <p className="highlight-letter">
       
        </p>
</div>

 

<div className="contentDivulgacao">
<div className="book-container" style={{ backgroundColor: 'rgb(250, 247, 233)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
  <div className="book-image">
    <img src={img1} alt="Capa do Livro" style={{ width: '400px', height: 'auto' }} />
  </div>
  <div className="text-contentDivulgacao">
    <p>
    Figura 1. Gravura da fábula “Da formiga e da cigarra”, contida em <em>“La vida del Ysopet con sus fabulas ystoriadas”,</em> publicada em Saragoça, em 1489, por Juan Hurus, f. 75v.
    </p>
  </div>
</div>
</div>


<div className="container" style={{  backgroundColor: 'rgb(250, 247, 233)', display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
  
  <div className="left-column" style={{ width: '45%', textAlign: 'justify' }}>
    <p>
   <em>"No tempo do inverno, a formiga secava, no sol, o trigo que havia colhido no 
    verão. E a cigarra, chegando nela com fome, rogava que lhe desse um pouco daquele trigo 
    para que não morresse. À qual disse a formiga: — Amiga, que fizeste no estio? Responde a 
    cigarra: — Não tive espaço para colher, porque andava pelos </em>
    </p>
    
  </div>

   
  <div className="right-column" style={{ width: '45%', textAlign: 'justify' }}>
    <p>
   <em> cercados cantando. A formiga, rindo dela e metendo seu trigo em sua caixa, disse-lhe:
     — Se cantaste no verão, dança agora no inverno. 
     Essa fábula ensina ao preguiçoso que trabalhe quando pode e há tempo, porque, 
     depois, faltando-lhe de comer, não peça a outros, os quais rirão em vez de dar-lhe algo”. </em>   </p>
     
  </div>
</div>

<div className="contentDivulgacao" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', textAlign: 'center', padding: '0', margin: '0' }}>
  <div className="book-container" style={{ margin: '0', padding: '0' }}>
    <div className="book-image">
      <img src={img2} alt="Capa do Livro" style={{ width: '600px', height: 'auto' }} />
    </div>
  </div>
</div>

 
 
<p className="highlight-letter">
"Quem não conhece a famosa fábula da cigarra e da formiga? A cigarra que cantava no verão, em vez de trabalhar, 
como a formiga, para ter reservas de alimento durante o inverno? Essa historieta, 
muito popular, tem uma longa tradição. Sua origem remonta à Grécia Antiga, a um personagem, 
de existência incerta, de nome Esopo (século VII a.C.), a quem é atribuída essa e uma série de outros 
relatos fabulistas. Entendidas como um gênero literário específico, essas pequenas narrativas chamam a 
atenção por terem, comumente, protagonistas do mundo animal, que representam, de modo alegórico,
 condutas e tipos humanos. Ao avaliarem a virtude do comportamento dos personagens, as fábulas transmitem
  lições ou ensinamentos morais àqueles que as ouvem ou as leem, situados tanto no início do texto <em>(promitio)</em> como
no fim <em>(epimitio). </em>
 </p>


 <p className="paragraph-content">
 Na Idade Média, as fábulas imputadas a Esopo foram difundidas por meio das escolas, onde eram ensinadas. 
 Tornaram-se conhecidas com base na tradição latina do fabulista romano Fedro (século I d.C.), o qual se baseou na 
 fonte grega e incorporou outras. Entre os séculos VIII e IX, um tal <em>Romulus</em>, alimentando-se principalmente das fábulas 
 latinas de Fedro e de outros materiais, como os relatos de Aviano (fins do século IV e início do século V), compilou uma coleção de
  narrativas esópicas em prosa, em quatro livros, que fez muito sucesso nos séculos seguintes. Dela, derivaram outras coleções 
  que circularam amplamente no medievo, como a de Walter de Inglaterra <em>(Gualterius Anglicus)</em> e as denominadas <em>Romulus Neveletii e Romulus Nilantii.</em>  Além da fabulística ligada ao nome de Esopo, houve outras tradições presentes nas fábulas medievais,
   como os contos de origem oriental, especialmente a partir do século XIII – como <em>Calila e Dimna</em> – e os relatos compostos no próprio período.
 </p>

 <div className="section-title">
        <h2>FÁBULAS COMO <em>EXEMPLUM</em></h2>
      </div>
      <p className='paragraph-normal'> 
      As fábulas são um dos temas estudados pelo grupo de pesquisa internacional “Instrução e conversão no mundo 
      dos <em>exempla</em> ibéricos: pilares da moral cristã”, um acordo bilateral entre a Fundação de Amparo à Pesquisa do 
      Estado de São Paulo (FAPESP) e o Fundo Nacional Suíço (FNS), coordenado pelo historiador Leandro Alves Teodoro, 
      professor de História Medieval na Faculdade de Ciências e Letras da Universidade Estadual Paulista (UNESP), 
      Câmpus de Assis, e pelo filólogo Hugo Oscar Bizzarri, professor de Filologia Hispânica e História da Língua no 
      Departamento de Espanhol da Universidade de Friburgo (Suíça).
        </p>



        <div className="contentDivulgacao" >
  <div className="book-image" style={{ flex: '1', textAlign: 'center' }}>
    <img src={img3} alt="Capa do Livro" style={{ width: '400px', height: 'auto', marginBottom: '10px' }} />
    <p style={{ marginTop: '10px' }}>
      Figura 2. Representação de Esopo (Séc. VII a.C.), fabulista grego, contida em <em>“La vida del Ysopet con sus fabulas ystoriadas”</em>, publicada em Saragoça, em 1489, por Juan Hurus, f. 1v.
    </p>
  </div>
         
        
          <div className="text-contentDivulgacao">
          <p className='paragraph-normal'>  
          Devido as suas características (brevidade, simplicidade, didatismo, estética etc.), a fábula também foi 
          inserida no mundo do relato breve medieval. Considerada um tipo de <em>exemplum</em> (exemplo; plural <em>exempla</em>: exemplos), 
          ela serviu como ilustração a argumentos éticos, religiosos, morais e políticos da época. Nesse sentido, uma mesma 
          fábula podia ganhar interpretações distintas a depender do contexto ou do autor/tradutor, assim como 
          determinadas ações de seus personagens. Por exemplo, se, na citada historieta da cigarra e da formiga, a 
          atitude precavida do segundo animal, que junta o trigo para a sobrevivência durante o inverno, é vista como 
          algo positivo na maioria dos documentos, no <em>Libro de los gatos</em>, coleção castelhana de <em>exempla</em>, composta entre os fins 
          do século XIV e início do XV, o mesmo comportamento é interpretado negativamente. A moral compara a formiga aos
           homens que juntam riquezas para serem roubadas por ladrões, senhores ou outros indivíduos poderosos.
            </p>

            <p className='paragraph-normal'> 
            No campo religioso, os pregadores, em particular, foram os grandes promotores do gênero ao inserir os relatos 
            fabulistas em coleções de <em>exempla</em> e em sermões, visando admoestar o público acerca dos vícios e das virtudes. 
            Em terras hispânicas, além do mencionado <em>Libro de los gatos</em>, cuja fonte é a obra latina <em>Fabulae</em> do pregador 
            inglês Odo de Cheriton (1180/90-1246/47), a interpretação cristianizada das fábulas aparece também em outras 
            coleções de exemplos, como o <em>Espéculo de los legos</em> – tradução castelhana de <em>Speculum laicorum,</em> do século XIII – e o 
            <em> Libro de los ejemplos</em> por a.b.c, do clérigo leonês Clemente Sanchez de Vercial (c. 1365-1438), ambas do 
                </p>
            </div>
           
            
</div>  

<div className="contentDivulgacao" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '20px' }}>
  
   
  <div className="text-contentDivulgacao" style={{ flex: '2', textAlign: 'justify', maxWidth: '60%' }}>
  <p className='paragraph-normal'> 
     século XV. Pode-se, ainda, encontrar uma leitura doutrinal 
    das fábulas no clérigo Juan Ruiz (c. 1283-c. 1351), que, ao abordar os pecados capitais no <em>Libro de Buen Amor</em>, 
    poema escrito no primeiro terço do século XIV, utilizou-se da fabulística de tradição esópica para representar os 
    vícios humanos. Para ilustrar a avareza, o primeiro dos pecados capitais, ele se baseou na fábula “Do alano que levava o 
    pedaço de carne na boca”, que narra a história de um cão que carregava um pedaço de carne na boca e, ao vê-lo refletido na
     água do rio, desejando tê-lo, acabou deixando-o cair. Essa fábula, de ampla difusão na Idade Média, é um exemplo da
      atitude gananciosa, comportamento que as pessoas deviam evitar.
     </p>

     <p className="paragraph-content">

     Se as narrativas fabulistas atribuídas a Esopo não eram desconhecidas na península Ibérica – cuja origem 
     remonta às aulas de gramáticas, aos textos árabes e à tradição literária sobre o mundo animal –, foi somente em 
     fins do século XV, com a impressão de <em>Esopete ystoriado</em>, em Saragoça, em 1482, que elas ganharam um maior destaque.
      A edição castelhana, feita por um tradutor anônimo, teve como base a versão bilíngue (latim e alemão) de 1476 ou 1477,
       conhecida como <em>Ulmer Aesop</em>. Tal edição foi realizada por um médico e humanista alemão, Heinrich Steinhöwel, o qual reuniu 
       diversos materiais concernentes às fábulas de Esopo e outras narrações exemplares. Depois da primeira impressão em 
       castelhano, outras três foram impressas. Os professores Leandro Alves Teodoro e Hugo O. Bizzarri trabalham, 
       atualmente, numa edição crítica de <em>Esopete ystoriado</em>, cuja previsão de lançamento é até 2025.
</p>
  </div>
 
  <div className="book-image" style={{ flex: '1', textAlign: 'justify' }}>
    <img src={img4} alt="Capa do Livro" style={{ width: '400px', height: 'auto', marginLeft: '20px' }} />
    <p style={{ marginTop: '10px', textAlign: 'center' }}>
    Figura 3. Rafaela Possari apresentando o pôster de sua pesquisa no Congresso de Iniciação Científica da Unesp – CIC, em setembro deste ano.    </p>
   

<div className="section-title">
        <h2>O ESTUDO DAS FÁBULAS NO ENSINO MÉDIO</h2>
      </div>
      <p className="paragraph-normal">

Entre outubro de 2023 e setembro de 2024, dois estudantes da Escola Técnica Estadual Pedro D’Arcádia Neto (Etec), 
situada na cidade de Assis/São Paulo, desenvolveram pesquisas sobre as fábulas de Esopo, no projeto 
“As narrativas moralizantes ibéricas de finais da Idade Média”, sob a orientação do professor Leandro Alves Teodoro. 
Rafaela Rodrigues Possari, de 17 anos, e Heitor Roberto Gonçalves, de 16 anos, foram selecionados por meio de edital para o 
Programa Institucional de Iniciação Científica e Tecnológica para o Ensino Médio – PIBIC Júnior,
criado pelo Conselho Nacional de Desenvolvimento Científico e Tecnológico (CNPq), que visa despertar a vocação
 científica dos alunos do Ensino Médio e Profissional.
</p>

<p className="paragraph-content">

Em suas investigações, os dois bolsistas compararam aspectos das fábulas esópicas, 
como, por exemplo, suas funções e os públicos a quem eram direcionadas em dois períodos históricos: 
no contexto ibérico medieval e no mundo contemporâneo. Com base na bibliografia e na análise de fontes, 
eles perceberam que, tanto na Idade Média como na atualidade, as fábulas contêm uma lição moral, 

</p>

 

</div>
</div>


<div className="contentDivulgacao" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '20px' }}>
  
   
  <div className="text-contentDivulgacao" style={{ flex: '2', textAlign: 'justify', maxWidth: '60%' }}>
  <p className="paragraph-normal">

      contudo, no medievo, elas foram utilizadas também para fins religiosos e destinadas, principalmente,
      a um público adulto, ao passo que, na contemporaneidade, elas são dirigidas, sobretudo, a um público infanto-juvenil.
    </p>

    <p className="paragraph-content">
Além de estabelecerem o primeiro contato com a pesquisa científica, os estudantes do 
3º ano do Ensino Médio tiveram a oportunidade de conhecer os documentos medievais, a literatura 
especializada e o trabalho do historiador na produção do conhecimento histórico. 
“Participar do PIBIC-Júnior foi uma experiência única, que me proporcionou muitas oportunidades 
na área em que desejo me aprofundar. Durante o período em que participei do programa, 
pude adquirir novos conhecimentos e aprimorar outros que já possuía”, diz Rafaela, 
que pretende prestar vestibular para História. “A minha experiência ao participar do PIBIC-Júnior foi bem surpreendente. Ter um contato inicial com o
 ‘mundo acadêmico’ e todos os processos necessários para se produzir uma pesquisa acadêmica foi o que
 mais chamou a minha atenção”, conta Heitor, que pensa em prestar vestibular para a área de Tecnologia da Informação.
 Questionados sobre os desafios surgidos no decorrer da pesquisa, ambos apontaram para a linguagem 
    pouco familiar dos documentos medievais e dos textos acadêmicos. “A principal dificuldade encontrada foi 
    o primeiro contato com os livros, documentos e artigos acadêmicos, visto que, no Ensino Médio, esse contato é escasso”,
    pontua Rafaela. 
    Todavia, conforme Heitor, essa dificuldade inicial foi sendo amenizada ao longo das investigações.
</p>
 
  </div>

 
  <div className="book-image" style={{ flex: '1', textAlign: 'center' }}>
    <img src={img5} alt="Capa do Livro" style={{ width: '350px', height: 'auto', marginLeft: '20px' }} />
    <p style={{ marginTop: '10px', textAlign: 'center' }}>
      Figura 4. Heitor Gonçalves apresentando o pôster de sua pesquisa no Congresso de Iniciação Científica da Unesp – CIC, em setembro deste ano.
    </p>
    <p className="paragraph-content">
     
    </p>
  </div>
</div>
 
<p className="paragraph-normal">
  “A prática de ler tais documentos, com o tempo, trouxe um aperfeiçoamento à minha leitura, 
  e consegui realizá-la de forma mais rápida. Basicamente, o tempo e a prática foram deixando a leitura mais fácil”.
  Os jovens pesquisadores se debruçaram sobre as fábulas contidas no “El Ysopete Ystoriado de 1482”, 
  edição crítica de Carmen Navarro e Elena dal Maso, de 2016, e no volume publicado pela Cosac Naify, 
  “Esopo. Fábulas completas”, de 2013. Para Rafaela, a fábula que mais chamou a sua atenção foi 
  “O lobo e o cordeiro”. “Ela trata sobre como a ‘razão’ do ‘forte’ se sobressai à ‘razão’ do ‘fraco’. 
  Achei interessante a maneira como a fábula aborda o tema, pois trata de maneira objetiva, o que facilita o
   entendimento”. Já Heitor destaca a fábula de um astrônomo que, certo dia, de tanto contemplar o céu, 
   distraído, caiu num buraco à sua frente. “O motivo de eu gostar dessa fábula é porque a enxergo como atual; 
   as pessoas sempre estão buscando, correndo atrás de um ‘sonho máximo’ e acabam deixando as coisas mais ‘simples’, 
   como as relações sociais e a própria saúde, de lado. No fim, elas até podem alcançar seus sonhos, 
   mas deixarão vários buracos para trás”.
</p>


<div className="content-columns-artigoII">
  <div className="column-artigoII">
    <h3 id='h3-artigoII'>Projeto</h3>
    <p>
    » Instrução e conversão no mundo dos <em>exempla</em> ibéricos: pilares da moral cristã 
      (FAPESP n. 21/02936-0). Modalidade: Convênio. Pesquisador responsável: 
      Leandro Alves Teodoro (Unesp-Assis). Pesquisador responsável no exterior: 
      Hugo Oscar Bizzarri (Universidade de Friburgo – Suíça).
    </p>
    <h3 id='h3-artigoII'>Materiais consultados</h3>
    <p>
    » BIZZARRI, Hugo Oscar. La fábula, 
    ¿una reinvención medieval? In: <em>Atalaya</em>, n. 14, 2014. 
    Disponível em: http://journals.openedition.org/atalaya/1412. 
    Acesso em: 01 out. de 2024.
    </p>

    <p>
    » <em>Cuentos latinos de la Edad Media. </em>
     Introducción, traducción y notas de Hugo O. Bizzarri. Madrid: Gredos, 2006.
    </p>

    <p>
    » KÜNZLER, Clara. Simbolismo, función y moralización de los 
    insectos en algunos ejemplarios medievales (
        Castilla siglos XIII-XV). In: BIZZARRI, Hugo Oscar; TEODORO, Leandro Alves (Dir.). <em>O exemplum y discurso ejemplar en la Península Ibérica.</em> São Paulo: Cultura Acadêmica, 2024, pp. 345-396.
        Disponível em: https://www.culturaacademica.com.br/catalogo/exemplum-y-discurso-exemplar-en-la-peninsula-iberica/ Acesso em: 01 out. de 2024.
    </p>

 
    <p>
     » LACARRA, María Jesús. Livro do Bom Amor / Libro de Buen Amor. 
     In: TEODORO, Leandro Alves (Org.). <em>O ensino da fé cristã na Península Ibérica (séculos XIV, XV e XVI).</em> Banco de dados, 2019. Disponível 
     em: https://umahistoriadapeninsula.com/Verbetes/63ea3665c93efb0299fa0b40 Acesso em 11 Out. de 2024.
 </p>
 <p>
 » TEODORO, Leandro Alves. Fábulas de Esopo no universo dos impressos. Usos do Passado. 
 In: <em>Sacralidades Medievais.</em> Disponível em: https://sacra-lidadesmedievais.com/textos-semanais 
 Acesso em: 11 de Out. de 2024.
 </p>
 <p>
 <em> » La vida del Ysopet con sus fabulas ystoriadas. Zaragoza: </em> Juan Hurus, 1489.
     </p>
  
   </div>

  
   
</div>


<p>  <strong>  Patrícia Serieiro.</strong> Bolsista de Jornalismo Científico-IV/ FAPESP (Processo 2024/01457-9).</p>

<div className="pdf-tbn-evento">
            <a href="https://bucket.server.umahistoriadapeninsula.com/artigos/As_fabulas_de_Esopo.pdf" target="_blank" class="btn btn-main mt-20">Baixar Arquivo</a>                      
     </div>

 
    <footer className="footerDivulgacao">
          <p>O ENSINO DA FÉ CRISTÃ NA PENÍNSULA IBÉRICA (SÉCS. XIV, XV E XVI)</p>
          <p> Outubro de 2024</p>
        </footer>

        </main>
     
    </div>   
    <Footer/>
    </div>
  );
}

export default As_fabulas_de_Esopo;
 